import styled from 'styled-components';

export default styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  margin: 10px 0;
  border: solid 1px #363636;
  background-color: #131313;
  z-index: 6;
  position: relative;
`;
