import styled from 'styled-components';
import NormalH4 from '../H4';
import textStyles from '../Typography/Point';

const H4 = styled(NormalH4)`
  margin: 0 -20px 20px;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 10px;
  ${({ theme }) => theme.media.md.min`
    font-size: 32px;
    margin: 0 0 20px 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const StyledH4 = styled(H4)`
  ${textStyles};
`;

export default StyledH4;
