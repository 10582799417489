import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
  z-index: 1;
  
  cursor: pointer;
  > span {
    display: block;
    width: 100%;
    height: 5px;
    background-color: currentColor;
    margin-bottom: 5px;
    transition: all 0.25s ease-in-out;
    transform-origin: center center;
  }

  color: #fff;

  ${props =>
    props.color === 'light' &&
    css`
      color: #fff;
    `}
  ${props =>
    props.color === 'dark' &&
    css`
      color: #131313;
    `};

  ${props =>
    props.open &&
    css`
      color: #131313;
      > span {
        &:nth-child(1) {
          transform: translateX(0) translateY(10px) rotate(45deg);
        }
        &:nth-child(2) {
          width: 0;
        }
        &:nth-child(3) {
          transform: translateX(0) translateY(-10px) rotate(-45deg);
        }
      }
    `}
  ${props =>
    !props.open &&
    css`
      @media (hover: hover) {
      &:hover {
        > span {
          &:nth-child(1) {
            transform: translateX(10px);
          }
          &:nth-child(2) {
            transform: translateX(-10px);
          }
          &:nth-child(3) {
            transform: translateX(10px);
          }
        }
      }
    `}
  }
  ${({ theme }) => theme.media.md.min`
    top: 30px;
    right: 30px;
    width: 45px;
   `};
`;

const Label = styled.p`
  font-weight: 900;
  font-size: 11px;
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  ${({ theme }) => theme.media.md.min`
    font-size: 13px;
   `};
`;

function Hamburger(props) {
  return (
    <Wrapper {...props} onClick={props.onClick}>
      <span />
      <span />
      <span />
      <Label>{Children.toArray(props.children)}</Label>
    </Wrapper>
  );
}

Hamburger.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default Hamburger;
