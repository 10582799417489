import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Title from '../Title';

import H3 from './H3';
import Cases from './Cases';
import Section from '../Section';

const StyledSection = styled(Section)`
  background-color: transparent;
  margin-top: -150px;
  padding-top: 0;
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.media.md.min`
    margin-top: -267px;
   `}
  ${({ theme }) => theme.media.lg.min`
    margin-top: -267px;
   `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
`;

function LatestCases(props) {
  return (
    <StyledSection className={props.className}>
      <Wrapper>
        <Title>
          <H3>
            <ReactMarkdown
              renderers={{ paragraph: React.Fragment }}
              source={props.content.title}
              escapeHtml={false}
            />
          </H3>
        </Title>
        <Cases cases={props.cases} />
      </Wrapper>
    </StyledSection>
  );
}

LatestCases.propTypes = {
  className: PropTypes.string,
  cases: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.object,
};

export default LatestCases;
