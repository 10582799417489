/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AnimatedButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  margin: 0;
  outline:0!important;
  cursor: pointer;
  .mousePart {
    animation-duration: 1.5s
    animation-iteration-count: infinite;
    animation-name: scroll;
  }
  @keyframes scroll{
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0
      transform: translateY(75%);
    }
  }  
`;

const Button = props => {
  const [hover, setHover] = useState(false);

  const hoverOn = () => {
    setHover(true);
  };

  const hoverOff = () => {
    setHover(false);
  };

  /*
  componentWillReceiveProps(nextProps) {
    setState({ hover: nextProps.hover });
  } */

  return (
    <AnimatedButton
      type="button"
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      size={props.size}
      aria-label="Scroll down"
      {...(props.down ? { down: 'down' } : {})}
      {...(props.vertical ? { vertical: 'vertical' } : {})}
      {...(hover ? { hover: 'hover' } : {})}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="54"
        viewBox="0 0 32 54"
      >
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <rect width="30" height="52" stroke="#FFF" rx="15" />
          <circle className="mousePart" cx="15" cy="15" r="4" fill="#FFF" />
        </g>
      </svg>
    </AnimatedButton>
  );
};

Button.defaultProps = {
  size: 'sm',
};

Button.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.string,
  down: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  hover: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
