/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function NormalImg(props) {
  let mobileX1;
  let mobileX2;
  const desktopX1 = require(`../../assets/images/${props.src.desktop.x1}`);
  const desktopX2 = require(`../../assets/images/${props.src.desktop.x2}`);

  try {
    mobileX1 = require(`../../assets/images/${props.src.mobile.x1}`);
    mobileX2 = require(`../../assets/images/${props.src.mobile.x2}`);
  } catch (e) {
    return (
      <img
        src={desktopX1}
        srcSet={`${desktopX1} 1x, ${desktopX2} 2x`}
        className={props.className}
        alt={props.alt}
      />
    );
  }

  return (
    <picture>
      <source
        srcSet={`${desktopX1} 1x, ${desktopX2} 2x`}
        media="(min-width: 768px)"
      />
      <img
        src={desktopX1}
        srcSet={`${mobileX1} 1x, ${mobileX2} 2x`}
        className={props.className}
        alt={props.alt}
      />
    </picture>
  );
}

const Img = styled(NormalImg)`
  width: auto;
  max-width: none;
  height: 100%;
  object-fit: cover;
  display: block;
  max-width: 1600px;
  margin: 0 auto;
  ${({ theme }) => theme.media.lg.min`
    width: 100%;
    height: 100%;
  `}
`;

NormalImg.propTypes = {
  src: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default Img;
