import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable global-require */
import styled from 'styled-components';
import Plx from 'react-plx';
import Section from './Section';
import H2 from './H2';
import P from './P';
import Media from './Media';
import Title from '../Title';

const StyledSection = styled(Section)``;

const StyledMedia = styled(Media)`
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Row = styled.div`
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.md.min`
    flex-direction: row;
    align-items: center;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const ColContent = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 0 0 0;
  order: 1;
  margin-bottom: 30px;
  ${({ theme }) => theme.media.md.min`
    order: 1;
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding: 0 40px 0 12.5%;
    margin-bottom: 0;
   `}
  ${({ theme }) => theme.media.lg.min`
    padding: 0 80px 0 12.5%;
   `}
`;

const ColMedia = styled.div`
  flex: 0 0 calc(100% + 40px);
  max-width: calc(100% + 40px);
  margin: 0 -20px;
  padding-left: 20px;
  order: 2;
  overflow: hidden;
  ${({ theme }) => theme.media.md.min`
    order: 2;
    margin: 0;
    flex: 0 0 37.5%;
    max-width: 37.5%;
   `}
  ${({ theme }) => theme.media.lg.min`
      padding-left: 0;

   `}
`;

const StyledP = styled(P)`
  padding-left: 20px;
  ${({ theme }) => theme.media.md.min`
    padding-left: 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: '50vh',
    properties: [
      {
        startValue: 1.1,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
];

function CaseSection(props) {
  return (
    <StyledSection>
      <Wrapper>
        <Row>
          <ColMedia>
            <Plx parallaxData={parallaxData}>
              <StyledMedia title={props.title} section={props.section} />
            </Plx>
          </ColMedia>
          <ColContent>
            {props.section.content && props.section.content.title && (
              <Title>
                <H2>{props.section.content.title}</H2>
              </Title>
            )}
            {props.section.content && props.section.content.body && (
              <StyledP>{props.section.content.body}</StyledP>
            )}
          </ColContent>
        </Row>
      </Wrapper>
    </StyledSection>
  );
}

CaseSection.propTypes = {
  section: PropTypes.object,
  title: PropTypes.string,
};

export default CaseSection;
