export const site = {
  id: 'home',
  title: 'Stijn Bessem',
  hero: {
    title: 'Stijn Bessem',
    typewriter: {
      title: 'your designer for',
      items: [
        'business identity',
        'design systems',
        'user interface design',
        'brand style guides',
        'motion design',
        'creative concepting',
        'service design',
        'social media strategy',
        'design & ux consult',
      ],
    },
    img: {
      active: '',
      inactive: '',
    },
  },
  latest: {
    title: 'Latest cases',
    body: '',
  },
  awards: {},
  dna: {
    title: "It's in<br />my DNA",
    body:
      'I am a  Senior Designer, UI Specialist, Motion Designer, UX Consultant and Digital Strategist based in Amsterdam, The Netherlands. Specialised in UI design & design systems. Creating beautiful, usable, professional websites & applications using best practice accessibility and user friendly. For all my design assignments my weapons of choice are Sketch, Figma & Adobe Suite for creating layouts, then prototyping for the best look and feel in inVision, Principle and After Effects. From workflow diagrams to detailed user interface mockups, I love shaping and crafting on-screen experiences. I am passionate about making sure that systems, interfaces, language and graphics are human-friendly, emotive, aesthetically pleasing, clear, on-brand and usable - with a touch of mellow smoothness.',
  },
  done: {
    title: 'I get<br />things<br />done',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin justo arcu, efficitur sit amet elit vel, vestibulum porttitor arcu. Suspendisse ac vestibulum metus.',
    usps: [
      {
        title: 'Strategy',
        gif: 'https://media.giphy.com/media/BmmfETghGOPrW/giphy.gif',
        body:
          'Social Media Strategy<br/>Business Identity<br/>Brand Innovation<br/>Creative Concept<br/>Digital Brand Experience<br/>User Experience Consult',
      },
      {
        title: 'Design',
        gif: 'https://media.giphy.com/media/Vt2NvYH0GD5QI/giphy.gif',
        body:
          'Design System<br/>Business Identity<br/>Brand Style Guides<br/>Printable Designs<br/>Service Design<br/>User Interface Design<br/>Motion Design',
      },
      {
        title: 'Prototype',
        gif: 'https://media.giphy.com/media/MkcgltZ9e1UI/giphy.gif',
        body:
          '<br/>A/B Testing<br/>Motion Prototyping<br/>Clickable Prototyping',
      },
    ],
  },
  comments: {
    title: 'What<br>others<br>say',
    chats: [
      {
        name: 'Adam',
        company: 'Vodafone Group',
        avatar: {
          active: 'stijn-bessem-testimonials-placeholder-act@2x.jpg',
          inactive: 'stijn-bessem-testimonials-placeholder-inact@2x.jpg',
        },
        body:
          "Stijn's overarching concern is delivering a product that meets the client's objectives. He spends time getting to know his clients and their projects so intimately that he is able to add tremendous value to them.",
        time: '08:14',
      },
      {
        name: 'Daniël',
        company: 'Virtua Gym',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-daniel.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-daniel@2x.jpg',
        },
        body:
          'Stijn is one of the most creative and versatile designers I’ve ever had the chance to collaborate with and brings with him an energy that is truly contagious to all those around him.',
        time: '18:15',
      },
      {
        name: 'Stefan',
        company: 'kPito',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-stefan.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-stefan@2x.jpg',
        },
        body: "You're a swiss army knife for the internet. Stay gold.",
        time: '22:32',
      },
      {
        name: 'Sophie',
        company: 'Douwe Egberts',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-sophie.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-sophie@2x.jpg',
        },
        body:
          'Stijn has exceptional skills in design. He can design a solution matching those goals with flare and originality; all the while being engaged and approachable',
        time: '11:20',
      },
      {
        name: 'Mick',
        company: 'Asics',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-mick.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-mick@2x.jpg',
        },
        body:
          'He creates top-quality designs which are stunningly smart and beautiful in every detail. Plus, he is always on time and meets deadlines. He’s also an absolute pleasure to work with — creative, intelligent, and incredibly productive.',
        time: '21:05',
      },
      {
        name: 'Bob',
        company: 'Chunk',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-bob.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-bob@2x.jpg',
        },
        body:
          "Stijn is a highly skilled UI designer. Not only is he very fast at transforming wireframes into beautiful high fidelity designs, he's also knowledgeable on a process level, be it providing design in the appropriate tools to make the implementation easier or setting up of a design system",
        time: '22:32',
      },
      {
        name: 'Brian',
        company: 'HEDO',
        avatar: {
          active: 'testimonials/stijn-bessem-testimonials-brian.jpg',
          inactive: 'testimonials/stijn-bessem-testimonials-brian@2x.jpg',
        },
        body:
          "I've found him to be incredibly professional, responsive, knowlegeable and talented. We look forward to partnering with on him all of our future web design work.",
        time: '22:32',
      },
    ],
  },
  more: {
    title: 'More<br />cases',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin justo arcu, efficitur sit amet elit vel, vestibulum porttitor arcu. Suspendisse ac vestibulum metus.',
  },
  footer: {
    body: '© 2022 STIJNBESSEM.COM, Inc. Alle rechten voorbehouden',
  },
};
