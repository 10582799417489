import styled from 'styled-components';
import NormalH4 from '../H4';

const Strapline = styled(NormalH4)`
  font-family: 'Saira Condensed', sans-serif;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  font-weight: 300;
`;

export default Strapline;
