import { css } from 'styled-components';

// Require context image folder
const images = require.context('./assets/images', true);

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

const spacing = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '1rem',
  4: '1.5rem',
  5: '3rem',
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[`${label}`] = {};
  acc[`${label}`].min = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  acc[`${label}`].max = (...args) => css`
    @media (max-width: ${breakpoints[label]}-1px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const theme = {
  maxWidth: 1110,
  breakpoints,
  spacing,
  images,
  media,
};

export default theme;
