import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
/* eslint-disable global-require */
import styled from 'styled-components';
import Plx from 'react-plx';
import Section from './Section';
import H2 from './H2';
import H5 from './H5';
import P from './P';
import AnimatedButton from '../Button/AnimatedButton';
import Media from './Media';
import Title from '../Title';

const StyledSection = styled(Section)`
  padding: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -20px;
    right: -20px;
    height: 100%;
    background-image: linear-gradient(to bottom, #131313, rgba(19, 19, 19, 0));
  }
  ${({ theme }) => theme.media.md.min`
    xxmargin: -75px 0 0 0 !important;

   `}
  ${({ theme }) => theme.media.lg.min`
    xxmargin: -150px 0 0 0 !important;
   `}
`;

const StyledMedia = styled(Media)`
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  object-position: center center;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
  z-index: 1;
`;

const Row = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const ColContent = styled.div`
  flex: 1 1 auto;
`;
const ColImage = styled.div`
  margin: 0 -20px;
  height: 100%;
  height: 400px;
  overflow: hidden;
  > div {
    height: 100%;
    width: 100%;
  }
`;

const StyledAnimatedButton = styled(AnimatedButton)`
  display: block;
  margin: 30px auto 0;
  text-transform: uppercase;
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: 500,
    properties: [
      {
        startValue: 1.1,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
];

function CaseSection(props) {
  return (
    <StyledSection>
      <ColImage>
        <Plx parallaxData={parallaxData}>
          <StyledMedia title={props.title} section={props.section} />
        </Plx>
      </ColImage>
      <Wrapper>
        <Row>
          <ColContent>
            <H5>next project</H5>
            <br />
            {props.section.content && props.section.content.title && (
              <Title>
                <H2>{props.section.content.title}</H2>
              </Title>
            )}
            {props.section.content && props.section.content.body && (
              <P>{props.section.content.body}</P>
            )}
            <StyledAnimatedButton
              size="md"
              horizontal="horizontal"
              onClick={() => {
                props.history.push(`/case/${props.section.to}`);
                return false;
              }}
            >
              View
            </StyledAnimatedButton>
          </ColContent>
        </Row>
      </Wrapper>
    </StyledSection>
  );
}

CaseSection.propTypes = {
  section: PropTypes.object,
  history: PropTypes.object,
  title: PropTypes.string,
};

export default withRouter(CaseSection);
