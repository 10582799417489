import React from 'react';

function PhoneLogo() {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m42.990467 28.783871c.0423798.3248928-.056564.6073782-.2964164.8474561l-2.8159564 2.7965015c-.1270356.1412427-.2928531.2614029-.4975216.3601689-.2046685.0989045-.4057736.1624637-.6033154.1906776-.0141151 0-.0565641.0036349-.127174.0106971-.0704716.0070275-.1622195.0106278-.2751401.0106278-.2682555 0-.7023285-.0459039-1.3022189-.1377462-.5998905-.0918424-1.3338395-.3178653-2.201847-.677965-.8682152-.3601688-1.8527067-.9004914-2.9536475-1.6207599-1.1009408-.7202339-2.2725262-1.709002-3.5146523-2.9660965-.9880549-.9745746-1.8067289-1.9067764-2.4560221-2.7965708-.6492932-.8898635-1.1715507-1.7126022-1.5667727-2.4682506-.3952565-.7556484-.691673-1.4406755-.8892839-2.0550812-.197611-.6144057-.3317042-1.1440658-.4022795-1.5889803-.0705754-.4449145-.0988055-.7944902-.0846904-1.048727.014115-.2542369.0211726-.3954796.0211726-.4237281.0282301-.1977398.0917479-.3990106.1905534-.6038125s.2187836-.3707621.3599343-.4978805l2.8159563-2.8177917c.197611-.1977398.4234521-.2966097.6775234-.2966097.1834959 0 .3458192.052966.4869698.158898.1411507.1059321.2611288.2365815.3599343.3919485l2.2654687 4.30084c.1270356.2259883.1623233.473163.105863.7415242-.0564603.2683611-.1764384.4943494-.3599343.6779649l-1.0374576 1.0381338c-.0282301.0282485-.0529315.0741524-.0741041.1377116s-.0317589.1165252-.0317589.158898c.0564603.2966097.1834959.6355922.3811069 1.0169474.1693808.3389825.4305096.7521174.7833863 1.2394047s.8539617 1.0486923 1.5032549 1.6842845c.6351781.6497856 1.1997809 1.154659 1.6938083 1.5148971.4939237.3600304.9069278.6249643 1.238632.7944555.3317041.1694913.5857753.2718922.7621791.307099l.2645884.0530007c.0282301 0 .0742079-.0106279.1376565-.0317797.0635178-.021221.1093918-.0459038.1376565-.074187l1.2068039-1.2288114c.2542096-.2259537.5504877-.3389479.8892839-.3389479.2400254 0 .430475.0423382.5716257.1271185h.0211381l4.0862779 2.41525c.2965203.183754.4729241.4167352.5294189.6992206z"
        fill="currentColor"
        transform="translate(-23 -13)"
      />
    </svg>
  );
}

// We require the use of src and alt, only enforced by react in dev mode
PhoneLogo.propTypes = {};

export default PhoneLogo;
