import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Grid from '../../components/Grid';

import HomePage from '../HomePage';
import CasePage from '../CasePage';
import NotFoundPage from '../NotFoundPage';

function Container({ strokeCount, location }) {
  const t = 2 * (strokeCount * 0.125 + 0.2) * 1000;
  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.key}
        classNames="louvre"
        appear
        timeout={{
          appear: 100,
          enter: t,
          exit: t,
        }}
      >
        <Routes className="route-section">
          <Grid />
          <Switch location={location}>
            <Route exact path="/" component={HomePage} />
            <Route path="/case/:id" component={CasePage} />
            <Route path="" component={NotFoundPage} />
          </Switch>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

const Routes = styled.div``;

Container.propTypes = {
  location: PropTypes.object,
  strokeCount: PropTypes.number,
};

export default withRouter(Container);
