import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const t = ((this.props.strokeCount + 1) * 0.125 + 0.2) * 1000;
    if (this.props.location !== prevProps.location) {
      setTimeout(() => window.scroll(0, 0), t);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  strokeCount: PropTypes.number,
  location: PropTypes.object,
  children: PropTypes.object,
};

export default withRouter(ScrollToTop);
