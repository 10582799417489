import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import USP from './USP';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 30px;
    &:nth-child(2) {
      flex-direction: row-reverse;
    }
  }
  ${({ theme }) => theme.media.md.min`
    > * {
      flex-direction: column!important;
      margin-bottom: 0;
      &:nth-child(1) {
        margin-top: 25%;
      }
      &:nth-child(2) {
        margin-top: 12.5%;
      }
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    flex-direction: row;
    justify-content: space-between;
    justify-content: space-around;
   `}
  ${({ theme }) => theme.media.lg.min`
    justify-content: space-between;
   `}
`;

function USPS(props) {
  return (
    <Wrapper>
      {props.usps.map((usp, i) => (
        <USP
          title={usp.title}
          backgroundImage={usp.gif}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {usp.body}
        </USP>
      ))}
    </Wrapper>
  );
}

USPS.propTypes = {
  usps: PropTypes.arrayOf(PropTypes.object),
};

export default USPS;
