import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;

  ${({ theme }) => theme.media.md.min`
    flex: 0 0 16.666%;
    max-width: 16.666%;
   `}
  ${({ theme }) => theme.media.lg.min`
      flex: 0 0 12.5%
      max-width: 12.5%;
   `}
`;
