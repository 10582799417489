/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Sticky from 'react-stickynode';
import H2 from './H2';
import Cases from './Cases';
import Section from '../Section';
import Title from '../Title';
import P from './P';

const Mountain = styled.div`
  height: 300px;
  position: absolute;
  top: 100%;
  margin-top: -300px;
  width: 100%;
  left: 0;
  z-index: 5;
  &:first-child {
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  ${({ theme }) => theme.media.xxl.min`
    width: 100%;
   `}
`;

const StyledSection = styled(Section)`
  position: relative;
  margin-bottom: 10px;
  .sticky-outer-wrapper {
    .sticky-inner-wrapper {
      position: relative !important;
    }
    &.active {
      ${Mountain} {
        position: fixed;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: block;
  color: #fff;
  position: relative;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
`;

const FlexWrapper = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  right: -20px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  overflow: hidden;

  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Flex = styled.div`
  align-self: flex-end;
  flex: 1 0 auto;
  padding: 20px 0;
  color: #fff;
  max-height: 300px;
  position: relative;
`;

function MoreCases(props) {
  return (
    <StyledSection>
      <Sticky enableTransforms={false}>
        <FlexWrapper>
          <Flex>
            <Mountain>
              <picture>
                <source
                  srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-back-2560x400.png')}`}
                  media="(min-width: 1440px)"
                />
                <img
                  srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-back-1440x400.png')}`}
                  alt="…"
                />
              </picture>
            </Mountain>
            <Mountain>
              <picture>
                <source
                  srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-front-2560x400.png')}`}
                  media="(min-width: 1440px)"
                />
                <img
                  srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-front-1440x400.png')}`}
                  alt="…"
                />
              </picture>
              <P>{props.footer.body}</P>
            </Mountain>
          </Flex>
        </FlexWrapper>
      </Sticky>
      <Wrapper>
        <Title>
          <H2>
            <ReactMarkdown
              renderers={{ paragraph: React.Fragment }}
              source={props.content.title}
              escapeHtml={false}
            />
          </H2>
        </Title>
        <Cases cases={props.cases} />
      </Wrapper>
    </StyledSection>
  );
}

MoreCases.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.object,
  footer: PropTypes.object,
};

export default MoreCases;
