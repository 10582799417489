import React from 'react';
import Img from './Img';
import IndigoImages from '../../assets/trophies/stijn-bessem-indigo-award-logo-light.svg';

function IndigoLogo() {
  return <Img src={IndigoImages} />;
}

// We require the use of src and alt, only enforced by react in dev mode
IndigoLogo.propTypes = {};

export default IndigoLogo;
