import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import H2 from './H2';
import Chats from './Chats';
import Section from '../Section';
import Title from '../Title';

const StyledSection = styled(Section)`
  overflow: hidden;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
  color: #fff;
  > * {
    order: 2;
  }
  ${({ theme }) => theme.media.md.min`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
   `}
  ${({ theme }) => theme.media.lg.min`
    flex-wrap: nowrap;
   `}
`;

const TitleWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  order: 1;
  ${({ theme }) => theme.media.md.min`
    flex: 0 0 100%;
    max-width: 100%;
    padding-left:0;
    order: 1;
   `}
  ${({ theme }) => theme.media.lg.min`
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    padding-left:0;
    order: 2;
   `}
`;

function Comments(props) {
  const leftSide = [...props.content.chats];
  const rightSide = leftSide.splice(0, Math.ceil(leftSide.length / 2));
  return (
    <StyledSection>
      <Wrapper>
        <Chats chats={leftSide} />
        <TitleWrapper>
          <Title>
            <H2>
              <ReactMarkdown
                renderers={{ paragraph: React.Fragment }}
                source={props.content.title}
                escapeHtml={false}
              />
            </H2>
          </Title>
        </TitleWrapper>
        <Chats reverse="reverse" chats={rightSide} />
      </Wrapper>
    </StyledSection>
  );
}

Comments.propTypes = {
  content: PropTypes.object,
};

export default Comments;
