import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import P from '../P';
import H4 from './H4';

const Wrapper = styled.div`
  color: #fff;
  > div {
    padding: 0 20px;
  }
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const List = styled(P)`
  padding: 0;
  margin: 0;
  line-height: 1.78;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

function Category(props) {
  return (
    <Wrapper>
      <div>
        <H4>{props.title}</H4>
        <List>{Children.toArray(props.children)}</List>
      </div>
    </Wrapper>
  );
}

Category.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Category;
