import React from 'react';

function AwwardLogo() {
  return (
    <svg
      height="12"
      viewBox="0 0 100 12"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#ffffff">
        <path d="m57.2478849 8.27005076h-3.8137055l-.9164129 2.78646364h-2.036379l3.8279188-10.86971237h2.064467l3.8294416 10.86971237h-2.0387479zm-1.9147208-6.19932318s-.245516 1.07225042-.4585448 1.68324873l-.9637902 2.90744501h2.8458545l-.9651438-2.90744501c-.1994924-.61370558-.4275804-1.68324873-.4275804-1.68324873z" />
        <path d="m63.1265651.18900169h3.5052454c1.1783418 0 1.7143824.09086295 2.1871404.30778342 1.0883249.48714044 1.7764806 1.53079526 1.7764806 2.96920474 0 1.30135364-.6886633 2.50862944-1.8370559 2.97225042v.03079526s.1526227.15177665.3683587.53620982l2.2351946 4.05566835h-2.2035533l-2.1588832-4.05634517h-1.9v4.05516077h-1.9737733v-10.87191204h.0011845v.00118443zm3.7492386 5.09746193c1.0558376 0 1.7135364-.62741117 1.7135364-1.7143824 0-1.04043993-.4428088-1.68324873-1.9891709-1.68324873h-1.5010152v3.39915397z" />
        <path d="m74.7311337.18900169h3.7055837c3.3522843 0 5.5399323 1.98984772 5.5399323 5.41759729 0 3.43231811-2.1871404 5.44940782-5.5399323 5.44940782h-3.7055837zm3.5983079 9.16886633c2.2047378 0 3.6108291-1.30050761 3.6108291-3.75109983 0-2.41641286-1.4404399-3.71895093-3.6108291-3.71895093h-1.6238578v7.47005076z" />
        <path d="m87.5162437 8.27005076s1.1786802 1.14839256 2.709137 1.14839256c.8263959 0 1.577665-.42707276 1.577665-1.31489002 0-1.94703892-5.1292724-1.61049069-5.1292724-4.94585448 0-1.80795263 1.5609137-3.1534687 3.6428088-3.1534687 2.1441624 0 3.245516 1.16345178 3.245516 1.16345178l-.8580372 1.60846023s-1.0436548-.94856176-2.4057529-.94856176c-.9155669 0-1.6377327.53604061-1.6377327 1.30135364 0 1.93011844 5.1118443 1.45431472 5.1118443 4.93113367 0 1.72944163-1.3153976 3.18324872-3.5817259 3.18324872-2.4181049 0-3.7494077-1.48307951-3.7494077-1.48307951z" />
        <path d="m34.9685279.18900169-1.7917089 7.51912014c-.1370559.61167512-.1827412 1.1465313-.1827412 1.1465313h-.031472c-.0160745 0-.0314721-.52030457-.199154-1.1465313l-1.9285956-7.51912014h-1.7142132l-1.9588833 7.51912014c-.1692047.62470389-.1834179 1.1465313-.1991539 1.1465313h-.0304569s-.0480541-.5357022-.1830795-1.1465313l-1.2829103-5.63028765-.9641286 4.10423012 1.2199661 4.8744501h2.2810491l1.6688663-6.42825721c.1835872-.70456853.2763114-1.42351946.2763114-1.42351946h.028934s.0917089.71912014.2766497 1.42351946l1.6707276 6.42825721h2.279357l2.8037225-10.86971237z" />
        <path d="m47.2321489.18900169-1.7923858 7.51912014c-.1367174.61167512-.1827411 1.1465313-.1827411 1.1465313h-.0321489c-.0138748 0-.0285956-.52030457-.1969543-1.1465313l-1.9304569-7.51912014h-1.7126903l-1.9593909 7.51912014c-.1697123.62470389-.1832487 1.1465313-.1989848 1.1465313h-.031472s-.0458545-.5357022-.1830796-1.1465313l-1.2906937-5.65634518-.9641286 4.10456853 1.2294416 4.90118442h2.2800339l1.6680203-6.42859559c.1834179-.70456853.2769881-1.42335025.2769881-1.42335025h.0304569s.0918781.71878172.2756345 1.42335025l1.6698815 6.42859559h2.2802031l2.8033841-10.86852791z" />
        <path d="m6.7676819 8.27005076h-3.81252116l-.91827411 2.78646364h-2.03688663l3.82758037-10.86751271h2.06751269l3.82707276 10.86751271h-2.03671743zm-1.91387479-6.19932318s-.24602369 1.07225042-.45922166 1.68324873l-.96463621 2.90744501h2.84754653l-.96615905-2.90626058c-.19796955-.61302876-.4285956-1.68443316-.4285956-1.68443316z" />
        <path d="m20.5006768 7.70659898c.1680203.62639594.1825719 1.14720813.1976312 1.14720813h.0309644s.0470389-.53705584.183418-1.14720813l1.7912013-7.51979695h2.0365482l-2.8027072 10.86971237h-2.2800339l-1.6680203-6.42910323c-.1830795-.70406092-.2769881-1.42318105-.2769881-1.42318105h-.0304569s-.0906937.71912013-.2756345 1.42318105l-1.6692048 6.42859563h-2.281049l-2.7245347-10.86700511h2.036379l1.7152284 7.51912014c.1380711.61167512.1837564 1.1465313.1837564 1.1465313h.0307952c.0164129 0 .0289341-.52030457.199154-1.1465313l1.9595601-7.51912014h1.7118443z" />
        <path d="m98.042132 3.72301184c1.0406091 0 1.8686971.84128596 1.8686971 1.8998308 0 1.06565144-.828088 1.90118443-1.8686971 1.90118443-1.0213198 0-1.8463621-.83536379-1.8463621-1.90118443 0-1.05786802.8250423-1.8998308 1.8463621-1.8998308zm0 3.57918782c.923181 0 1.6323181-.72910321 1.6323181-1.67800338 0-.94467005-.7099831-1.67817259-1.6323181-1.67817259-.9071066 0-1.6115059.73553299-1.6115059 1.67817259-.0001692.9500846.7050761 1.67800338 1.6115059 1.67800338zm-.6069374-2.72690355h.6959391c.3554991 0 .6099831.24263959.6099831.60981387 0 .30930626-.1871405.50795263-.4045686.56125212v.00981387s.0191202.02673435.0698816.11624366l.4143824.80270727h-.2576988l-.4451777-.87648054h-.4527919v.87648054h-.2272419zm.6714044 1.02199661c.2417936 0 .3983079-.15532995.3983079-.41302876 0-.25177665-.1566835-.40118443-.3983079-.40118443h-.4411168v.81387478z" />
      </g>
    </svg>
  );
}

// We require the use of src and alt, only enforced by react in dev mode
AwwardLogo.propTypes = {};

export default AwwardLogo;
