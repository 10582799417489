import { css } from 'styled-components';

const textStyles = css`
  display: inline-block;
  &:after {
    content: '.';
    color: #fbab7e;
    margin-left: 0.25rem;
  }
`;

export default textStyles;
