import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import H2 from './H2';
import P from './P';
// import Logo from './Logo';
import AwardLinks from './AwardLinks';
import AwardLink from './AwardLink';
import IndigoLogo from '../Logos/IndigoLogo';
import AwwardLogo from '../Logos/AwwardLogo';
import Section from '../Section';
import Title from '../Title';
import DNAString from './DNAString';

const StyledSection = styled(Section)`
  overflow: hidden;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
  ${({ theme }) => theme.media.md.min`
    flex-direction: row;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Body = styled.div`
  ${({ theme }) => theme.media.md.min`
    padding: 0 30px;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const LogoWrapper = styled.div`
  padding: 0 0;
  flex: 0 0 250px;
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  margin: 40px auto 0;
  &:before {
    content: '';
    display: block;
    padding-top: 100%; /* initial ratio of 1:1*/
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${({ theme }) => theme.media.md.min`
    flex: 0 0 33.3333%;
    max-width: 33.333%;
    padding: 0;
    margin: 0 auto 0;
   `}
  ${({ theme }) => theme.media.lg.min`
    flex: 0 0 37.5%;
    max-width: 37.5%;

    xxpadding-left: 12.5%;
   `}
`;

function DNA(props) {
  return (
    <StyledSection>
      <Wrapper>
        <AwardLinks>
          <AwardLink>
            <IndigoLogo />
          </AwardLink>
          <AwardLink>
            <AwwardLogo />
          </AwardLink>
        </AwardLinks>
        <Body>
          <Title>
            <H2>
              <ReactMarkdown
                renderers={{ paragraph: React.Fragment }}
                source={props.content.title}
                escapeHtml={false}
              />
            </H2>
          </Title>
          <P>{props.content.body}</P>
        </Body>
        <LogoWrapper>
          <div>
            <DNAString />
          </div>
        </LogoWrapper>
      </Wrapper>
    </StyledSection>
  );
}

DNA.propTypes = {
  content: PropTypes.object,
};

export default DNA;
