import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */
import styled, { css } from 'styled-components';
import { Spring, animated } from 'react-spring/renderprops';
import VisibilitySensor from 'react-visibility-sensor';
import Img from './Img';
import CheckIcon from './CheckIcon';

const Body = styled.div`
  border-radius: 10px;
  background-image: linear-gradient(282deg, #fbab7e, #f7ce68);
  padding: 10px 15px;
  border-top-right-radius: 0;
`;

const Comment = styled.div`
  display: flex;
`;

const Author = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin-right: 90px;
  margin-bottom: 10px;
  position: relative;
  padding-right: 20px;
  text-align: right;
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #d7fb7e;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
  }
  ${({ theme }) => theme.media.md.min`
  font-size: 14px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 14px;
   `}
`;

const Avatar = styled(Img)`
  height: 60px;
  width 60px;
  border-radius: 50%;
  margin-left: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  ${props =>
    props.reverse &&
    css`
      align-items: flex-start;
      ${Body} {
        border-top-right-radius: 10px;
        border-top-left-radius: 0;
      }
      ${Comment} {
        flex-direction: row-reverse;
      }
      ${Avatar} {
        margin-left: 0;
        margin-right: 30px;
      }
      ${Author} {
        margin-right: 0;
        margin-left: 90px;
        padding-right: 0;
        padding-left: 20px;
        text-align: left;
        &:before {
          right: auto;
          left: 0;
        }
      }
    `};
`;

const Company = styled.span`
  font-weight: bold;
`;

const P = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #131313;
  margin: 0;

  ${({ theme }) => theme.media.md.min`
  font-size: 16px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 16px;
   `}
`;

const Time = styled.p`
  display: flex;
  align-items: center;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  margin: 0;
  float: right;
  > span {
    margin-left: 5px;
  }
`;

function Chat(props) {
  let endProp;
  if (props.reverse) {
    endProp = 'translate(-100px, 0)';
  } else {
    endProp = 'translate(100px, 0)';
  }
  return (
    <VisibilitySensor partialVisibility="bottom" offset={{ bottom: 0 }}>
      {({ isVisible }) => (
        <Spring
          native
          delay={300}
          to={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translate(0, 0)' : endProp,
          }}
        >
          {springProps => (
            <Wrapper {...props}>
              <animated.div style={{ ...springProps }}>
                <Author>
                  <span>{props.chat.name}</span>
                  <span> from </span>
                  <Company>{props.chat.company} </Company>
                </Author>
                <Comment>
                  <Body>
                    <P>{props.chat.body}</P>
                    <Time>
                      <CheckIcon />
                      <span>{props.chat.time}</span>
                    </Time>
                  </Body>
                  <Avatar
                    src={require(`../../assets/images/${props.chat.avatar.inactive}`)}
                  />
                </Comment>
              </animated.div>
            </Wrapper>
          )}
        </Spring>
      )}
    </VisibilitySensor>
  );
}

Chat.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  reverse: PropTypes.string,
  chat: PropTypes.object,
};

export default Chat;
