/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */

import Img from './Img';
import Video from './Video';

function Media(props) {
  if (props.section.img)
    return (
      <Img
        src={props.section.img}
        className={props.className}
        alt={props.title}
      ></Img>
    );
  if (props.section.video)
    return (
      <Video
        title={props.title}
        video={props.section.video}
        className={props.className}
      ></Video>
    );
  return null;
}

Media.propTypes = {
  className: PropTypes.string,
  section: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default Media;
