import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 38px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  ${({ theme }) => theme.media.md.min`
    font-size: 38px;
   `}
  ${({ theme }) => theme.media.lg.min`
    font-size: 52px;
   `}
`;

export default H2;
