import styled from 'styled-components';

const H4 = styled.h4`
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  ${({ theme }) => theme.media.md.min`
  font-size: 28px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 28px;
   `}
`;

export default H4;
