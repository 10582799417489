import React from 'react';
import PropTypes from 'prop-types';
import Cursor from './Cursor';

class TypeWriter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      isDeleting: false,
      loopNum: 0,
      typingSpeed: 150,
    };
  }

  componentDidMount() {
    this.handleType();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleType = () => {
    const { dataText } = this.props;
    const { isDeleting, loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? 20 : 80,
    });

    if (!isDeleting && text === fullText) {
      this.timer = setTimeout(() => {
        this.setState({ isDeleting: true });
        this.timer = setTimeout(this.handleType, typingSpeed);
      }, 500);
    } else if (isDeleting && text === '') {
      this.timer = setTimeout(() => {
        this.setState({ isDeleting: false, loopNum: loopNum + 1 });
        this.timer = setTimeout(this.handleType, typingSpeed);
      }, 1000);
    } else {
      this.timer = setTimeout(this.handleType, typingSpeed);
    }
  };

  render() {
    return (
      <span className={this.props.className}>
        <span>{this.state.text}</span>
        <Cursor />
      </span>
    );
  }
}

TypeWriter.propTypes = {
  className: PropTypes.string,
  dataText: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TypeWriter;
