export const cases = [
  {
    id: 'rituals',
    title: 'Rituals',
    logo: 'stijn-bessem-clients-rituals.svg',
    intro: {
      img: {
        active: 'cases/rituals/stijnbessem-case-rituals-intro-card1.2@2x.jpg',
        inactive: 'cases/rituals/stijnbessem-case-rituals-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/rituals/stijn-bessem-case-rituals-header02@2x.jpg',
        inactive: 'cases/rituals/stijn-bessem-case-rituals-header01@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'N/A',
      },
      {
        title: 'Client',
        body: 'Rituals Cosmetics',
      },
      {
        title: 'Role',
        body:
          'UI Lead<br/>UX & UI Design<br/>Motion Design<br/>Digital Strategist',
      },
      {
        title: 'Links',
        body:
          '<a href="https://apps.apple.com/nl/app/rituals-home-body-cosmetics/id1424241203" target="_blank" rel="noreferrer">App Store</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/514636313?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Navigation Redesign',
          body:
            'We started transforming the insights we collected from our research into concrete designs. We made sure to keep a specific user at the front of our minds throughout this process so we could analyze our design choices through her eyes. The results are a clickable prototype that we can start conducting usability testing around to continue iterating solutions to best suit user goals while looking for opportunities to bring them joy along the way.',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/668728527?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Rituals Today',
          body:
            'A growing number of e-commerce companies now provide next-day delivery, and because of the growing popularity of Amazon Prime, that’s now the standard for online merchants of all kinds. In a more recent trend, internet retailers are increasingly providing same-day delivery, thereby reducing the amount of time between making an order and receiving it.',
        },
      },
      {
        type: 'right',
        video: {
          url:
            '//player.vimeo.com/video/668730445?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'The Art of Soulful Living',
          body:
            'The Art of Soulful Living is a compass for personal well-being. It aspires to connect and bring balance between body, mind and soul. Because we believe that that balance, that connection between these three human natures, ensures we achieve the ultimate form of well-being. You could think of it as a holistic approach to good living, where we touch on all the important pillars: from physical and mental to spiritual.',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/668733007?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Premium Body Therapy',
          body:
            'Body scrubs have been around for a while, but quite a variety has emerged over the years, including those made of coffee, salt, and sugar. You may even have a few of them hanging out in your bathroom cabinet right now.',
        },
      },
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/rituals/desktop/stijn-bessem-case-rituals-mm-01.jpg',
            x2: 'cases/rituals/desktop/stijn-bessem-case-rituals-mm-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/rituals/mobile/stijn-bessem-case-rituals-mm-01.jpg',
            x2: 'cases/rituals/mobile/stijn-bessem-case-rituals-mm-01@2x.jpg',
          },
        },
        content: {
          title: 'Meaningful Moments Redesign',
          body:
            'Send a special mail with a gift voucher, a relaxing meditation track or your favorite yoga video to the people you love',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/rituals/desktop/stijn-bessem-case-rituals-01.jpg',
            x2: 'cases/rituals/desktop/stijn-bessem-case-rituals-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/rituals/mobile/stijn-bessem-case-rituals-01.jpg',
            x2: 'cases/rituals/mobile/stijn-bessem-case-rituals-01@2x.jpg',
          },
        },
      },
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/514637748?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Advent Calendar Fastlane',
          body:
            'Create a distraction free, but delightful environment to showcase our annual bestseller. Selling 180.000 Advent calendars online while complement the technical solution to create Single Order Lines to support the supply process during the busiest time of the year',
        },
      },
      {
        type: 'bottom',
        img: {
          desktop: {
            x1:
              'cases/rituals/desktop/stijn-bessem-case-rituals-checkout-01.jpg',
            x2:
              'cases/rituals/desktop/stijn-bessem-case-rituals-checkout-01@2x.jpg',
          },
          mobile: {
            x1:
              'cases/rituals/mobile/stijn-bessem-case-rituals-checkout-01.jpg',
            x2:
              'cases/rituals/mobile/stijn-bessem-case-rituals-checkout-01@2x.jpg',
          },
        },
        content: {
          title: 'Checkout Redesign',
          body:
            'The ecommerce checkout process is the series of steps a customer must follow to purchase items in their online shopping cart. The best checkouts will have a clear flow and will be designed to be seamless and frictionless for the user.',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/rituals/desktop/stijn-bessem-case-rituals-02.jpg',
            x2: 'cases/rituals/desktop/stijn-bessem-case-rituals-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/rituals/mobile/stijn-bessem-case-rituals-02 .jpg',
            x2: 'cases/rituals/mobile/stijn-bessem-case-rituals-02@2x.jpg',
          },
        },
      },
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/514943012?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'App Homepage Redesign',
          body:
            'Videos let you establish authority and a more personal feel to your message. You will be far more likely to connect on an emotional level with your audience if you use video versus another content type.',
        },
      },

      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/514638107?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Upgraded UX for homepage',
          body:
            'Their home page is the lynchpin for the company’s entire online ecosystem. So when the company grew massively, a homepage redesign was in order.',
        },
      },
      {
        type: 'right',
        video: {
          url:
            '//player.vimeo.com/video/514640474?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'House of Rituals PDP',
          body:
            'A product detail page, also known as a PDP, is a web page on an eCommerce website that provides information on a specific product. This information includes size, color, price, shipping information, reviews, and other relevant information customers want to know before purchasing.',
        },
      },
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/514646944?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Sketch Design System',
          body:
            'A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of digital products. Our design system consists of: our design principles, foundational design language, brand guidelines, human interface guidelines, and working code. It is maintained by our core design systems team and continually improved by our amazing community of contributors.',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/514639816?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'QR On-boarding',
          body:
            'By making the QR code onboarding process as seamless as possible, organizations have the opportunity to create and maintain a fully engaged workforce that communicates exceptionally. You no longer have to worry about whether your employees are logged into and using the communication tools you’ve provided. Now, you don’t have to spend hours getting each new employee set up and explain how the tool works. ',
        },
      },
      {
        type: 'right',
        video: {
          url:
            '//player.vimeo.com/video/514639829?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Order Tracking',
          body:
            'Purchase order tracking is a challenge in any industry, but it is especially crucial for the cosmetic field.  A reliable purchase order tracking process can improve the efficiency of your supply chain and ensure that you won’t lose pieces of valuable, or even potentially must have equipment.',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/514677037?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Product set reveal',
          body:
            'UI animation can be defined as the implementation of motion in order to enhance the overall presentation and interactivity of a product.',
        },
      },
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/514646916?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'inVision DSM integration',
          body:
            'InVision Design System Manager connects design and code so teams can work smarter, faster, and more in sync. Seamlessly works with the tools designers already use. Integration with Sketch Libraries lets you upload files to DSM in a single click, sync changes, and push and pull design system assets.',
        },
      },
      {
        type: 'next',
        to: 'filtermaster',
        img: {
          desktop: {
            x1:
              'cases/douwe-egberts-filter-master/stijnbessem-case-douwe-egberts-filter-master-bottom-01.jpg',
            x2:
              'cases/douwe-egberts-filter-master/stijnbessem-case-douwe-egberts-filter-master-bottom-01@2x.jpg',
          },
        },
        content: {
          title: 'Filter Master',
        },
      },
    ],
  },
  {
    id: 'filtermaster',
    title: 'Filter Master',
    logo: 'stijn-bessem-clients-douwe-egberts.svg',
    intro: {
      img: {
        active:
          'cases/douwe-egberts-filter-master/stijnbessem-case-douwe-egberts-filter-master-intro-card01@2x.jpg',
        inactive:
          'cases/douwe-egberts-filter-master/stijnbessem-case-douwe-egberts-filter-master-intro-card02@2x.jpg',
      },
    },
    hero: {
      img: {
        active:
          'cases/douwe-egberts-filter-master/stijn-bessem-case-douwe-egberts-filter-master-header01@2x.jpg',
        inactive:
          'cases/douwe-egberts-filter-master/stijn-bessem-case-douwe-egberts-filter-master-header02@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Douwe Egberts',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult<br/>Prototyping',
      },
      {
        title: 'Links',
        body: 'N/A',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-01.jpg',
            x2:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-01@2x.jpg',
          },
          mobile: {
            x1:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-01.jpg',
            x2:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-01@2x.jpg',
          },
        },
        content: {
          title: 'Filter Coffee made easy',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-02.jpg',
            x2:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-02@2x.jpg',
          },
          mobile: {
            x1:
              'cases/5ghub/douwe-egberts-filter-master/stijn-bessem-case-douwe-egberts-filter-master-02.jpg',
            x2:
              'cases/5ghub/douwe-egberts-filter-master/stijn-bessem-case-douwe-egberts-filter-master-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-03.jpg',
            x2:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-03@2x.jpg',
          },
          mobile: {
            x1:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-03.jpg',
            x2:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-03@2x.jpg',
          },
        },
        content: {
          title: 'Usabillity Tested',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-04.jpg',
            x2:
              'cases/douwe-egberts-filter-master/desktop/stijn-bessem-case-douwe-egberts-filter-master-04@2x.jpg',
          },
          mobile: {
            x1:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-04.jpg',
            x2:
              'cases/douwe-egberts-filter-master/mobile/stijn-bessem-case-douwe-egberts-filter-master-04@2x.jpg',
          },
        },
      },
      {
        type: 'next',
        to: '5ghub',
        img: {
          desktop: {
            x1: 'cases/5ghub/stijnbessem-case-5ghub-bottom-01.1@2x.jpg',
            x2: 'cases/5ghub/stijnbessem-case-5ghub-bottom-01.1@2x.jpg',
          },
        },
        content: {
          title: '5G HUB',
        },
      },
    ],
  },
  {
    id: '5ghub',
    title: '5G HUB',
    logo: 'stijn-bessem-clients-5ghub.svg',
    intro: {
      img: {
        active: 'cases/5ghub/stijnbessem-case-5ghub-intro-card1.2@2x.jpg',
        inactive: 'cases/5ghub/stijnbessem-case-5ghub-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/5ghub/stijn-bessem-case-5ghub-header01@2x.jpg',
        inactive: 'cases/5ghub/stijn-bessem-case-5ghub-header02@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: '5GHUB Eindhoven',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Design<br/>Prototyping',
      },
      {
        title: 'Links',
        body:
          '<a href="https://www.5ghub.nl/" target="_blank" rel="noreferrer">5GHUB.nl</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-01.jpg',
            x2: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-01.jpg',
            x2: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-01@2x.jpg',
          },
        },
        content: {
          title: 'Connecting Technologies',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-02.jpg',
            x2: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-02.jpg',
            x2: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-03.jpg',
            x2: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-03.jpg',
            x2: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-03@2x.jpg',
          },
        },
        content: {
          title: 'Atomic Design',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-04.jpg',
            x2: 'cases/5ghub/desktop/stijn-bessem-case-5ghub-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-04.jpg',
            x2: 'cases/5ghub/mobile/stijn-bessem-case-5ghub-04@2x.jpg',
          },
        },
      },
      {
        type: 'next',
        to: 'hollandsnieuwe',
        img: {
          desktop: {
            x1:
              'cases/hollandsnieuwe/stijnbessem-case-hollandsnieuwe-bottom-01.1@2x.jpg',
            x2:
              'cases/hollandsnieuwe/stijnbessem-case-hollandsnieuwe-bottom-01.1@2x.jpg',
          },
        },
        content: {
          title: 'hollandsnieuwe',
        },
      },
    ],
  },
  {
    id: 'hollandsnieuwe',
    title: 'hollandsnieuwe',
    logo: 'stijn-bessem-clients-hollandsnieuwe.svg',
    intro: {
      img: {
        active:
          'cases/hollandsnieuwe/stijnbessem-case-hollandsnieuwe-intro-card1.2@2x.jpg',
        inactive:
          'cases/hollandsnieuwe/stijnbessem-case-hollandsnieuwe-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active:
          'cases/hollandsnieuwe/stijn-bessem-case-hollandsnieuwe-00.1@2x.jpg',
        inactive:
          'cases/hollandsnieuwe/stijn-bessem-case-hollandsnieuwe-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body:
          '<a href="http://www.hollandsnieuwe.nl/" target="_blank" rel="noreferrer">hollandsnieuwe</a>',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult<br/>Design System',
      },
      {
        title: 'Trophies',
        body:
          '<a href="https://www.indigoawards.com/winners/533" target="_blank" rel="noreferrer">Indigo</a><br/>Awwwards',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          mobile: {
            x1:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-01.jpg',
            x2:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-01@2x.jpg',
          },
          desktop: {
            x1:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-01.jpg',
            x2:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-01@2x.jpg',
          },
        },
        content: {
          title: 'all about visual hierarchy',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          mobile: {
            x1:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-02.jpg',
            x2:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-02@2x.jpg',
          },
          desktop: {
            x1:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-02.jpg',
            x2:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/359047986?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'design research',
          body: '',
        },
      },
      {
        type: 'right',
        video: {
          url:
            '//player.vimeo.com/video/359047525?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Sustainable design system',
          body: '',
        },
      },
      {
        type: 'bottom',
        img: {
          mobile: {
            x1:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-03.jpg',
            x2:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-03@2x.jpg',
          },
          desktop: {
            x1:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-03.jpg',
            x2:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-03@2x.jpg',
          },
        },
        content: {
          title: 'promotional landingpages',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          mobile: {
            x1:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-04.jpg',
            x2:
              'cases/hollandsnieuwe/mobile/stijn-bessem-case-hollandsnieuwe-04@2x.jpg',
          },
          desktop: {
            x1:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-04.jpg',
            x2:
              'cases/hollandsnieuwe/desktop/stijn-bessem-case-hollandsnieuwe-04@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/360785557?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Design Showreel',
          body: '',
        },
      },
      {
        type: 'next',
        to: 'pickwick',
        img: {
          desktop: {
            x1: 'cases/pickwick/stijnbessem-case-pickwick-bottom-01.1@2x.jpg',
            x2: 'cases/pickwick/stijnbessem-case-pickwick-bottom-01.1@2x.jpg',
          },
        },
        content: {
          title: 'Pickwick',
        },
      },
    ],
  },
  {
    id: 'pickwick',
    title: 'Pickwick',
    logo: 'stijn-bessem-clients-pickwick.svg',
    intro: {
      img: {
        active: 'cases/pickwick/stijnbessem-case-pickwick-intro-card1.2@2x.jpg',
        inactive:
          'cases/pickwick/stijnbessem-case-pickwick-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/pickwick/stijn-bessem-case-pickwick-00.1@2x.jpg',
        inactive: 'cases/pickwick/stijn-bessem-case-pickwick-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Jacobs Douwe Egberts',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult<br/>Digital Strategy',
      },
      {
        title: 'Links',
        body:
          '<a href="http://www.pickwick.nl" target="_blank" rel="noreferrer">Pickwick</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/359526493?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Redesign everything',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          mobile: {
            x1: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-02.jpg',
            x2: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-02@2x.jpg',
          },
          desktop: {
            x1: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-02.1.jpg',
            x2: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-02.1@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-03.1.jpg',
            x2: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-03.1@2x.jpg',
          },
          mobile: {
            x1: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-03.1.jpg',
            x2: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-03.1@2x.jpg',
          },
        },
        content: {
          title: 'Responsive designs',
          body:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin justo arcu, efficitur sit amet elit vel, vestibulum porttitor arcu. Suspendisse ac vestibulum metus.',
        },
      },
      {
        type: 'full',
        img: {
          mobile: {
            x1: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-04.jpg',
            x2: 'cases/pickwick/mobile/stijn-bessem-case-pickwick-04@2x.jpg',
          },
          desktop: {
            x1: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-04.jpg',
            x2: 'cases/pickwick/desktop/stijn-bessem-case-pickwick-04@2x.jpg',
          },
        },
      },
      {
        type: 'next',
        to: 'voxi',
        img: {
          desktop: {
            x1: 'cases/voxi/stijnbessem-case-voxi-bottom-01.2@2x.jpg',
            x2: 'cases/voxi/stijnbessem-case-voxi-bottom-01.2@2x.jpg',
          },
        },
        content: {
          to: 'voxi',
          title: 'Voxi',
        },
      },
    ],
  },
  {
    id: 'voxi',
    title: 'VOXI',
    logo: 'stijn-bessem-clients-voxi.svg',
    intro: {
      img: {
        active: 'cases/voxi/stijnbessem-case-voxi-intro-card1.1@2x.jpg',
        inactive: 'cases/voxi/stijnbessem-case-voxi-intro-card1.2@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/voxi/stijn-bessem-case-voxi-00.1@2x.jpg',
        inactive: 'cases/voxi/stijn-bessem-case-voxi-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'VOXI UK',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult<br/>Digital Style Guide',
      },
      {
        title: 'Links',
        body:
          '<a href="https://www.voxi.co.uk/" target="_blank" rel="noreferrer">voxi.co.uk</a><br/><a href="https://invis.io/4DTZVYL7XR9#/341591945_20180417_01_SB_VOXI_WAVOXI_M01-01" target="_blank" rel="noreferrer">inVision proto</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/voxi/desktop/stijn-bessem-case-voxi-01.jpg',
            x2: 'cases/voxi/desktop/stijn-bessem-case-voxi-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/voxi/mobile/stijn-bessem-case-voxi-01.jpg',
            x2: 'cases/voxi/mobile/stijn-bessem-case-voxi-01@2x.jpg',
          },
        },
        content: {
          title: 'Dark mode all the way',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/voxi/desktop/stijn-bessem-case-voxi-02.jpg',
            x2: 'cases/voxi/desktop/stijn-bessem-case-voxi-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/voxi/mobile/stijn-bessem-case-voxi-02.jpg',
            x2: 'cases/voxi/mobile/stijn-bessem-case-voxi-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/359131217?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: "High Fidelity proto's",
          body: '',
        },
      },
      {
        type: 'right',
        img: {
          desktop: {
            x1: 'cases/voxi/desktop/stijn-bessem-case-voxi-04@2x.jpg',
            x2: 'cases/voxi/desktop/stijn-bessem-case-voxi-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/voxi/mobile/stijn-bessem-case-voxi-04.jpg',
            x2: 'cases/voxi/mobile/stijn-bessem-case-voxi-04@2x.jpg',
          },
        },
        content: {
          title: 'Prototype for testing',
          body: '',
        },
      },
      {
        type: 'next',
        to: 't-mobile',
        img: {
          desktop: {
            x1: 'cases/tmobile/stijnbessem-case-t-mobile-bottom-01.1.jpg',
            x2: 'cases/tmobile/stijnbessem-case-t-mobile-bottom-01.1@2x.jpg',
          },
        },
        content: {
          title: 't-mobile',
        },
      },
    ],
  },
  {
    id: 't-mobile',
    title: 'T-Mobile',
    logo: 'stijn-bessem-clients-t-mobile.svg',
    intro: {
      img: {
        active: 'cases/tmobile/stijnbessem-case-t-mobile-intro-card1.2@2x.jpg',
        inactive:
          'cases/tmobile/stijnbessem-case-t-mobile-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/tmobile/stijn-bessem-case-t-mobile-00.1@2x.jpg',
        inactive: 'cases/tmobile/stijn-bessem-case-t-mobile-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Oberon',
      },
      {
        title: 'Client',
        body: 'T-Mobile',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Design',
      },
      {
        title: 'Links',
        body:
          '<a href="https://projects.invisionapp.com/share/WQB1LSSXB#/205812308_CEO_Login" target="_blank" rel="noreferrer">inVision proto</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/tmobile/desktop/stijn-bessem-case-t-mobile-02.jpg',
            x2: 'cases/tmobile/desktop/stijn-bessem-case-t-mobile-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/tmobile/mobile/stijn-bessem-case-t-mobile-01.jpg',
            x2: 'cases/tmobile/mobile/stijn-bessem-case-t-mobile-01@2x.jpg',
          },
        },
        content: {
          title: 'mobile first',
          body: '',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/360525576?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'Prototype for testing',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/tmobile/desktop/stijn-bessem-case-t-mobile-03.jpg',
            x2: 'cases/tmobile/desktop/stijn-bessem-case-t-mobile-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/tmobile/mobile/stijn-bessem-case-t-mobile-03.jpg',
            x2: 'cases/tmobile/mobile/stijn-bessem-case-t-mobile-03@2x.jpg',
          },
        },
      },
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/360526890?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Custom header animation',
          body: '',
        },
      },
      {
        type: 'next',
        to: 'douweegberts',
        img: {
          desktop: {
            x1:
              'cases/douweegberts/stijnbessem-case-douwe-egberts-bottom-01.1@2x.jpg',
            x2:
              'cases/douweegberts/stijnbessem-case-douwe-egberts-bottom-01.1@2x.jpg',
          },
        },
        content: {
          title: 'Douwe Egberts',
        },
      },
    ],
  },
  {
    id: 'douweegberts',
    title: 'Douwe Egberts',
    logo: 'stijn-bessem-clients-douwe-egberts.svg',
    intro: {
      img: {
        active:
          'cases/douweegberts/stijnbessem-case-douwe-egberts-intro-card1.1@2x.jpg',
        inactive:
          'cases/douweegberts/stijnbessem-case-douwe-egberts-intro-card1.2@2x.jpg',
      },
    },
    hero: {
      img: {
        active:
          'cases/douweegberts/stijn-bessem-case-douwe-egberts-00.2@2x.jpg',
        inactive:
          'cases/douweegberts/stijn-bessem-case-douwe-egberts-00.1@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Jocobs Douwe Egberts',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult<br/>Design System',
      },
      {
        title: 'Links',
        body:
          '<a href="https://www.de.nl/" target="_blank" rel="noreferrer">Douwe Egberts</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        video: {
          url:
            '//player.vimeo.com/video/359539578?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '16by9',
        },
        content: {
          title: 'Starting from scratch',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1:
              'cases/douweegberts/desktop/stijn-bessem-case-douwe-egberts-02.1.jpg',
            x2:
              'cases/douweegberts/desktop/stijn-bessem-case-douwe-egberts-02.1@2x.jpg',
          },
          mobile: {
            x1:
              'cases/douweegberts/mobile/stijn-bessem-case-douwe-egberts-02.jpg',
            x2:
              'cases/douweegberts/mobile/stijn-bessem-case-douwe-egberts-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1:
              'cases/douweegberts/desktop/stijn-bessem-case-douwe-egberts-03.jpg',
            x2:
              'cases/douweegberts/desktop/stijn-bessem-case-douwe-egberts-03@2x.jpg',
          },
          mobile: {
            x1:
              'cases/douweegberts/mobile/stijn-bessem-case-douwe-egberts-03.1.jpg',
            x2:
              'cases/douweegberts/mobile/stijn-bessem-case-douwe-egberts-03.1@2x.jpg',
          },
        },
        content: {
          title: 'Mobile First',
          body: '',
        },
      },

      {
        type: 'next',
        to: 'hedo',
        img: {
          desktop: {
            x1: 'cases/hedo/stijnbessem-case-hedo-bottom-01.2@2x.jpg',
            x2: 'cases/hedo/stijnbessem-case-hedo-bottom-01.2@2x.jpg',
          },
        },
        content: {
          title: 'HEDO',
        },
      },
    ],
  },
  {
    id: 'hedo',
    title: 'HEDO',
    logo: 'stijn-bessem-clients-hedo.svg',
    intro: {
      img: {
        active: 'cases/hedo/stijnbessem-case-hedo-intro-card1.2@2x.jpg',
        inactive: 'cases/hedo/stijnbessem-case-hedo-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/hedo/stijn-bessem-case-hedo-00.2@2x.jpg',
        inactive: 'cases/hedo/stijn-bessem-case-hedo-00.1@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Personal',
      },
      {
        title: 'Client',
        body: 'Hedo Smart Home',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Design<br/>Prototyping',
      },
      {
        title: 'Links',
        body:
          '<a href="https://projects.invisionapp.com/share/WBB1LOWTP#/screens/218715530_HEDO-Startup-01" target="_blank" rel="noreferrer">inVision proto</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/hedo/desktop/stijn-bessem-case-hedo-01.png',
            x2: 'cases/hedo/desktop/stijn-bessem-case-hedo-01@2x.png',
          },
          mobile: {
            x1: 'cases/hedo/mobile/stijn-bessem-case-hedo-01.png',
            x2: 'cases/hedo/mobile/stijn-bessem-case-hedo-01@2x.png',
          },
        },
        content: {
          title: 'Luxury domotica software',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/hedo/desktop/stijn-bessem-case-hedo-02.1.jpg',
            x2: 'cases/hedo/desktop/stijn-bessem-case-hedo-02.1@2x.jpg',
          },
          mobile: {
            x1: 'cases/hedo/mobile/stijn-bessem-case-hedo-02.1.jpg',
            x2: 'cases/hedo/mobile/stijn-bessem-case-hedo-02.1@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/359737438?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'high fidelity proto',
          body: '',
        },
      },
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/hedo/desktop/stijn-bessem-case-hedo-04.jpg',
            x2: 'cases/hedo/desktop/stijn-bessem-case-hedo-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/hedo/mobile/stijn-bessem-case-hedo-04.jpg',
            x2: 'cases/hedo/mobile/stijn-bessem-case-hedo-04@2x.jpg',
          },
        },
        content: {
          title: 'custom icon set',
          body: '',
        },
      },
      {
        type: 'right',
        img: {
          desktop: {
            x1: 'cases/hedo/desktop/stijn-bessem-case-hedo-03.jpg',
            x2: 'cases/hedo/desktop/stijn-bessem-case-hedo-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/hedo/mobile/stijn-bessem-case-hedo-03.jpg',
            x2: 'cases/hedo/mobile/stijn-bessem-case-hedo-03@2x.jpg',
          },
        },
        content: {
          title: 'light mode included',
          body: '',
        },
      },
      {
        type: 'next',
        to: 'vodafonecvm',
        img: {
          desktop: {
            x1:
              'cases/vodafonecvm/stijnbessem-case-vodafonecvm-bottom-01.2.jpg',
            x2:
              'cases/vodafonecvm/stijnbessem-case-vodafonecvm-bottom-01.2@2x.jpg',
          },
        },
        content: {
          title: 'Vodafone',
        },
      },
    ],
  },
  {
    id: 'vodafonecvm',
    title: 'Vodafone',
    logo: 'stijn-bessem-logo-vodafone.svg',
    intro: {
      img: {
        active:
          'cases/vodafonecvm/stijnbessem-case-vodafonecvm-intro-card1.1@2x.jpg',
        inactive:
          'cases/vodafonecvm/stijnbessem-case-vodafonecvm-intro-card1.2@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/vodafonecvm/stijn-bessem-case-vodafonecvm-00.2@2x.jpg',
        inactive: 'cases/vodafonecvm/stijn-bessem-case-vodafonecvm-00.1@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Vodafone',
      },
      {
        title: 'Role',
        body: 'UX Design<br/>UI Design',
      },
      {
        title: 'Links',
        body:
          '<a href="https://www.vodafone.nl/inspiratie/" target="_blank" rel="noreferrer">Project page</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-02.jpg',
            x2:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-02.jpg',
            x2:
              'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-02@2x.jpg',
          },
        },
        content: {
          title: 'Clear wireframing',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-04.jpg',
            x2:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-04.jpg',
            x2:
              'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-04@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-03.jpg',
            x2:
              'cases/vodafonecvm/desktop/stijn-bessem-case-vodafonecvm-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-03.jpg',
            x2:
              'cases/vodafonecvm/mobile/stijn-bessem-case-vodafonecvm-03@2x.jpg',
          },
        },
        content: {
          title: 'Mobile First',
          body: '',
        },
      },

      {
        type: 'next',
        to: 'vodafonerebranding',
        img: {
          desktop: {
            x1:
              'cases/vodafonerebranding/stijnbessem-case-vodafonerebranding-bottom-01.2@2x.jpg',
            x2:
              'cases/vodafonerebranding/stijnbessem-case-vodafonerebranding-bottom-01.2@2x.jpg',
          },
        },
        content: {
          title: 'Vodafone',
        },
      },
    ],
  },
  {
    id: 'vodafonerebranding',
    title: 'Vodafone',
    logo: 'stijn-bessem-logo-vodafone.svg',
    intro: {
      img: {
        active:
          'cases/vodafonerebranding/stijnbessem-case-vodafonerebranding-intro-card1.2@2x.jpg',
        inactive:
          'cases/vodafonerebranding/stijnbessem-case-vodafonerebranding-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active:
          'cases/vodafonerebranding/stijn-bessem-case-vodafonereb-00.1@2x.jpg',
        inactive:
          'cases/vodafonerebranding/stijn-bessem-case-vodafonereb-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Vodafone',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult',
      },
      {
        title: 'Links',
        body:
          '<a href="https://directsales.vodafone.nl/friendsfamily" target="_blank">Friends & Family</a><br/><a href="https://www.vodafone.nl/kleinbedrijf/advies/" target="_blank" rel="noreferrer">Advise Tool</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-01.jpg',
            x2:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-01@2x.jpg',
          },
          mobile: {
            x1:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-01.jpg',
            x2:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-01@2x.jpg',
          },
        },
        content: {
          title: 'New guidelines, new designs',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-02.jpg',
            x2:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-02@2x.jpg',
          },
          mobile: {
            x1:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-02.jpg',
            x2:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-02@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-03.jpg',
            x2:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-03@2x.jpg',
          },
          mobile: {
            x1:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-03.jpg',
            x2:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-03@2x.jpg',
          },
        },
        content: {
          title: 'Responsive designs',
          body: '',
        },
      },
      {
        type: 'right',
        img: {
          desktop: {
            x1:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-04.jpg',
            x2:
              'cases/vodafonerebranding/desktop/stijn-bessem-case-vodafonerebranding-04@2x.jpg',
          },
          mobile: {
            x1:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-04.jpg',
            x2:
              'cases/vodafonerebranding/mobile/stijn-bessem-case-vodafonerebranding-04@2x.jpg',
          },
        },
        content: {
          title: 'flow charts included',
          body: '',
        },
      },

      {
        type: 'next',
        to: 'kpito',
        img: {
          desktop: {
            x1: 'cases/kpito/stijnbessem-case-kpito-bottom-01.2@2x.jpg',
            x2: 'cases/kpito/stijnbessem-case-kpito-bottom-01.2@2x.jpg',
          },
        },
        content: {
          title: 'kPito',
        },
      },
    ],
  },
  {
    id: 'kpito',
    title: 'kpito',
    logo: 'stijn-bessem-clients-kpito.svg',
    intro: {
      img: {
        active: 'cases/kpito/stijnbessem-case-kpito-intro-card1.1@2x.jpg',
        inactive: 'cases/kpito/stijnbessem-case-kpito-intro-card1.2@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/kpito/stijn-bessem-case-kpito-00.1@2x.jpg',
        inactive: 'cases/kpito/stijn-bessem-case-kpito-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Oberon',
      },
      {
        title: 'Client',
        body: 'kPito',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Design',
      },
      {
        title: 'Links',
        body:
          '<a href="http://kpito.it/" target="_blank" rel="noreferrer">Project page</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/kpito/desktop/stijn-bessem-case-kpito-01.jpg',
            x2: 'cases/kpito/desktop/stijn-bessem-case-kpito-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/kpito/mobile/stijn-bessem-case-kpito-01.jpg',
            x2: 'cases/kpito/mobile/stijn-bessem-case-kpito-01@2x.jpg',
          },
        },
        content: {
          title: 'Back to school',
          body: '',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/359786847?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'testing is essential',
          body: '',
        },
      },
      {
        type: 'right',
        img: {
          desktop: {
            x1: 'cases/kpito/desktop/stijn-bessem-case-kpito-02.jpg',
            x2: 'cases/kpito/desktop/stijn-bessem-case-kpito-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/kpito/mobile/stijn-bessem-case-kpito-02.jpg',
            x2: 'cases/kpito/mobile/stijn-bessem-case-kpito-02@2x.jpg',
          },
        },
        content: {
          title: 'proud of the result',
          body: '',
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/kpito/desktop/stijn-bessem-case-kpito-03.jpg',
            x2: 'cases/kpito/desktop/stijn-bessem-case-kpito-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/kpito/mobile/stijn-bessem-case-kpito-03.jpg',
            x2: 'cases/kpito/mobile/stijn-bessem-case-kpito-03@2x.jpg',
          },
        },
        content: {
          title: "it's been a succes",
          body: '',
        },
      },
      {
        type: 'next',
        to: 'teatopics',
        img: {
          desktop: {
            x1: 'cases/teatopics/stijnbessem-case-teatopics-bottom-01.2@2x.jpg',
            x2: 'cases/teatopics/stijnbessem-case-teatopics-bottom-01.2@2x.jpg',
          },
        },
        content: {
          title: 'teatopics',
        },
      },
    ],
  },
  {
    id: 'teatopics',
    title: 'teatopics',
    logo: 'stijn-bessem-clients-pickwick.svg',
    intro: {
      img: {
        active:
          'cases/teatopics/stijnbessem-case-teatopics-intro-card1.2@2x.jpg',
        inactive:
          'cases/teatopics/stijnbessem-case-teatopics-intro-card1.1@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/teatopics/stijn-bessem-case-teatopics-00.1@2x.jpg',
        inactive: 'cases/teatopics/stijn-bessem-case-teatopics-00.2@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body: 'Pickwick',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Consult',
      },
      {
        title: 'Links',
        body:
          '<a href="https://www.pickwick.nl/teatopics/" target="_blank" rel="noreferrer">Project page</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-02.jpg',
            x2: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-02.jpg',
            x2: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-02@2x.jpg',
          },
        },
        content: {
          title: 'a platform for collectors',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-03.jpg',
            x2: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-03.jpg',
            x2: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-03@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-04.jpg',
            x2: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-04.jpg',
            x2: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-04@2x.jpg',
          },
        },
        content: {
          title: 'custom artwork',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-05.jpg',
            x2: 'cases/teatopics/desktop/stijn-bessem-case-teatopics-05@2x.jpg',
          },
          mobile: {
            x1: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-05.jpg',
            x2: 'cases/teatopics/mobile/stijn-bessem-case-teatopics-05@2x.jpg',
          },
        },
      },
      {
        type: 'next',
        to: 'creative',
        img: {
          desktop: {
            x1: 'cases/creative/stijnbessem-case-creative-bottom-01.1.jpg',
            x2: 'cases/creative/stijnbessem-case-creative-bottom-01.1@2x.jpg',
          },
        },
        content: {
          to: 'creative',
          title: 'Creative',
        },
      },
    ],
  },
  {
    id: 'creative',
    title: 'Creative',
    logo: 'sb-logo-light.svg',
    intro: {
      img: {
        active: 'cases/creative/stijnbessem-case-creative-intro-card1.1@2x.jpg',
        inactive:
          'cases/creative/stijnbessem-case-creative-intro-card1.2@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/creative/stijn-bessem-case-creative-header02@2x.jpg',
        inactive: 'cases/creative/stijn-bessem-case-creative-header01@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Personal',
      },
      {
        title: 'Client',
        body: 'The Universe',
      },
      {
        title: 'Role',
        body: 'Art Director<br/>Artist',
      },
    ],
    sections: [
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-01.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-01.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-01@2x.jpg',
          },
        },
        content: {
          title: 'Starting from scratch',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-02.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-02@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-02.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-02@2x.jpg',
          },
        },
      },
      {
        type: 'right',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-03.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-03@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-03.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-03@2x.jpg',
          },
        },
        content: {
          title: 'old school Graffiti ',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-04.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-04@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-04.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-04@2x.jpg',
          },
        },
      },
      {
        type: 'left',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-05.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-05@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-05.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-05@2x.jpg',
          },
        },
        content: {
          title: 'Wolf gang',
          body: '',
        },
      },
      {
        type: 'full',
        img: {
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-06.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-06@2x.jpg',
          },
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-06.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-06@2x.jpg',
          },
        },
      },
      {
        type: 'bottom',
        img: {
          mobile: {
            x1: 'cases/creative/mobile/stijn-bessem-case-creative-07.jpg',
            x2: 'cases/creative/mobile/stijn-bessem-case-creative-07@2x.jpg',
          },
          desktop: {
            x1: 'cases/creative/desktop/stijn-bessem-case-creative-07.jpg',
            x2: 'cases/creative/desktop/stijn-bessem-case-creative-07@2x.jpg',
          },
        },
        content: {
          title: 'Your next album cover',
          body: '',
        },
      },
      {
        type: 'next',
        to: 'chunk',
        img: {
          desktop: {
            x1: 'cases/chunk/stijnbessem-case-chunk-bottom-01.2.jpg',
            x2: 'cases/chunk/stijnbessem-case-chunk-bottom-01.2@2x.jpg',
          },
        },
        content: {
          to: 'chunk',
          title: 'chunk',
        },
      },
    ],
  },
  {
    id: 'chunk',
    title: 'chunk',
    logo: 'stijn-bessem-clients-chunk.svg',
    intro: {
      img: {
        active: 'cases/chunk/stijnbessem-case-chunk-intro-card02@2x.jpg',
        inactive: 'cases/chunk/stijnbessem-case-chunk-intro-card01@2x.jpg',
      },
    },
    hero: {
      img: {
        active: 'cases/chunk/stijn-bessem-case-chunk-header-02@2x.jpg',
        inactive: 'cases/chunk/stijn-bessem-case-chunk-header-01@2x.jpg',
      },
    },
    categories: [
      {
        title: 'Agency',
        body: 'Chunk Creative Agency',
      },
      {
        title: 'Client',
        body:
          '<a href="http://www.chunk.nl/" target="_blank" rel="noreferrer">Chunk Creative Agency</a>',
      },
      {
        title: 'Role',
        body: 'UI Design<br/>UX Design',
      },
      {
        title: 'Links',
        body:
          '<a href="https://invis.io/UQTZWEKRBEZ" target="_blank" rel="noreferrer">inVision proto</a>',
      },
    ],
    sections: [
      {
        type: 'bottom',
        img: {
          desktop: {
            x1: 'cases/chunk/desktop/stijn-bessem-case-chunk-01.jpg',
            x2: 'cases/chunk/desktop/stijn-bessem-case-chunk-01@2x.jpg',
          },
          mobile: {
            x1: 'cases/chunk/mobile/stijn-bessem-case-chunk-01.jpg',
            x2: 'cases/chunk/mobile/stijn-bessem-case-chunk-01@2x.jpg',
          },
        },
        content: {
          title: 'Customer Care Tool',
          body: '',
        },
      },
      {
        type: 'left',
        video: {
          url:
            '//player.vimeo.com/video/360812753?autoplay=1&loop=1&autopause=0&background=1&muted=1',
          format: '1by1',
        },
        content: {
          title: 'testing essential',
          body: '',
        },
      },
      {
        type: 'next',
        to: 'rituals',
        img: {
          desktop: {
            x1: 'cases/rituals/stijnbessem-case-rituals-bottom-01.jpg',
            x2: 'cases/rituals/stijnbessem-case-rituals-bottom-01@2x.jpg',
          },
        },
        content: {
          to: 'rituals',
          title: 'Rituals',
        },
      },
    ],
  },
];
