import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Chat from './Chat';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 30px;
  > * {
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
  }
  ${props =>
    props.reverse &&
    css`
      padding-left: 30px;
      padding-right: 0;
      margin-top: 0;
      align-items: flex-end;
    `}

  ${({ theme }) => theme.media.md.min`
    flex: 0 0 50%;
    max-width: 50%;
   `}
  ${({ theme }) => theme.media.lg.min`
    flex: 0 0 37.5%;
    max-width: 37.5%;
   `}    

  ${({ theme }) => theme.media.sm.max`
    padding-left: 0!important;
    padding-right: 0!important;
   `}  
`;

function Chats(props) {
  return (
    <Wrapper {...(props.reverse ? { reverse: 'reverse' } : {})}>
      {props.chats.map((chat, i) => (
        <Chat
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          chat={chat}
          {...((props.reverse && i % 2 === 1) || (!props.reverse && i % 2 === 0)
            ? { reverse: 'reverse' }
            : {})}
        />
      ))}
    </Wrapper>
  );
}

Chats.propTypes = {
  reverse: PropTypes.string,
  chats: PropTypes.arrayOf(PropTypes.object),
};

export default Chats;
