import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const timings = [...Array(30)]
  .map(
    (item, i) =>
      `&:nth-child(${i}) {
        animation-delay: ${i * 0.15 - 60}s;        
        &:before{
          animation-delay: ${i * 0.15 - 60}s;        
        }
        &:after{
          animation-delay: ${i * 0.15 - 60}s;        
        }
      };
    `,
  )
  .join('');

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  perspective: 1000px;
  animation: rotationDNA 10s linear infinite;
  transform-origin: center center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 2px;
    height: 25%;
    background-color: rgba(255, 255, 255, 0.1);
    transform-style: preserve-3d;
    position: relative;
    animation: rotation 4s linear infinite;
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
    &:after,
    &:before {
      content: '';
      position: absolute;
      left: -2px;
      right: -2px;
      height: 6px;
      background-color: #fff;
      border-radius: 4px;
      animation: rotation 4s linear infinite reverse;
      z-index: 1;
    }
    ${timings};
  }
`;

function DNAString() {
  return (
    <Wrapper>
      {[...Array(30)].map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} />
      ))}
    </Wrapper>
  );
}

export default DNAString;
