/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */

import Top from './Top';
import Bottom from './Bottom';
import Full from './Full';
import Left from './Left';
import Right from './Right';
import Next from './Next';

const sectionComponents = {
  top: Top,
  bottom: Bottom,
  full: Full,
  left: Left,
  right: Right,
  next: Next,
};

function Empty() {
  return <div />;
}

function CaseSection(props) {
  return props.case.sections.map((section, i) => {
    const Tagname = sectionComponents[section.type] || Empty;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Tagname key={i} title={props.case.title} section={section} />
    );
  });
}

CaseSection.propTypes = {
  case: PropTypes.object.isRequired,
};

export default CaseSection;
