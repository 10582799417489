import styled from 'styled-components';

const Name = styled.div`
  display: flex;
  flex-direction: column;
  > svg {
    width: 100%;
  }
  > * {
    margin: 0;
    &:nth-child(1) {
      opacity: 0.05;
    }
    &:nth-child(2) {
      opacity: 0.1;
    }
    &:nth-child(3) {
      opacity: 0.04;
    }
    &:nth-child(4) {
      opacity: 0.08;
    }
    &:nth-child(5) {
      opacity: 0.16;
    }
    &:nth-child(6) {
      opacity: 0.24;
    }
    &:last-child {
      opacity: 1;
      color: currentColor;
      filter: none;
    }
  }
  ${({ theme }) => theme.media.md.min`
    > * {
    &:nth-child(1) {
      display:none;
    }
    &:nth-child(2) {
      display:none;
    }
    &:nth-child(3) {
      display:none;
    }
  }
   `}
`;

export default Name;
