import styled from 'styled-components';

const H3 = styled.h3`
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  margin: 0;
  ${({ theme }) => theme.media.md.min`
  font-size: 32px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 32px;
   `}
`;

export default H3;
