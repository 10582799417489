import styled from 'styled-components';
import NormalH2 from '../H2';
import textStyles from '../Typography/Point';

const H2 = styled(NormalH2)`
  text-transform: uppercase;
  align-self: center;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  ${({ theme }) => theme.media.md.min`
  margin: 0 0 30px 0;
   `}
  ${({ theme }) => theme.media.lg.min`
  margin: 0 0 30px 0;
   `}
`;

const StyledH2 = styled(H2)`
  ${textStyles};
`;

export default StyledH2;
