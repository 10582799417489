import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H1 from '../H1';
import Name from './Name';

const StyledH1 = styled(H1)`
  white-space: nowrap;
  line-height: 1;
  xfilter: url(#myFilter);
  text-transform: uppercase;
  font-size: 32px;

  /* You can change the color of the outline here by changing the color of the heading */

  color: ${props => props.borderColor};
  text-shadow: -1px -1px 0 ${props => props.borderColor},
    1px -1px 0 ${props => props.borderColor},
    -1px 1px 0 ${props => props.borderColor},
    1px 1px 0 ${props => props.borderColor};

  text-shadow: none;
  filter: none;

  ${({ theme }) => theme.media.md.min`
    font-size: 55px;
   `}
  ${({ theme }) => theme.media.lg.min`
    font-size: 80px;
    line-height: 0.85;
   `}
`;

function HeaderTitle(props) {
  return (
    <Name className={props.className}>
      {[...Array(7)].map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledH1 borderColor={props.borderColor} key={i}>
          {Children.toArray(props.children)}
        </StyledH1>
      ))}
    </Name>
  );
}

HeaderTitle.propTypes = {
  borderColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default HeaderTitle;
