import styled from 'styled-components';
import P from '../P';

const StyledP = styled(P)`
  max-width: 640px;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

export default StyledP;
