import styled from 'styled-components';
import NormalP from '../P';

const P = styled(NormalP)`
  padding: 0 0 0 20px;
  ${({ theme }) => theme.media.md.min`
    padding: 0;
`}
  ${({ theme }) => theme.media.lg.min`
`}
`;
export default P;
