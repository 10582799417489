import styled from 'styled-components';
import NormalH5 from '../H5';
import textStyles from '../Typography/Underline';

const H5 = styled(NormalH5)`
  margin: 0 0 20px 0;
  font-size: 14px;
  text-transform: uppercase;
  ${({ theme }) => theme.media.md.min`
  font-size: 14px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 14px;
   `}
`;

const StyledH5 = styled(H5)`
  ${textStyles};
`;

export default StyledH5;
