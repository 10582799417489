import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */
import styled from 'styled-components';
import Plx from 'react-plx';
import Section from './Section';
import H2 from './H2';
import P from './P';
import Media from './Media';
import Title from '../Title';

const StyledSection = styled(Section)`
  padding: 0 !important;
  position: relative;
`;

const StyledMedia = styled(Media)``;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
`;

const Row = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const ColContent = styled.div``;
const ColMedia = styled.div`
  overflow: hidden;
  margin: 0 -20px;
  height: ${props => (props.fixedHeight ? '600px' : 'auto')}
  display: flex;
  align-items: center;
  justify-content: center;
  > .Plx {
    flex: 1 1 auto;
    align-self: stretch;
  }
  ${({ theme }) => theme.media.md.min`
    height: ${props => (props.fixedHeight ? '700px' : 'auto')}
   `}
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: '200vh',
    properties: [
      {
        startValue: 1.5,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
];

function CaseSection(props) {
  return (
    <StyledSection>
      <ColMedia fixedHeight={!!props.section.img}>
        <Plx parallaxData={parallaxData}>
          <StyledMedia title={props.title} section={props.section} />
        </Plx>
      </ColMedia>
      <Wrapper>
        <Row>
          <ColContent>
            {props.section.content && props.section.content.title && (
              <Title>
                <H2>{props.section.content.title}</H2>
              </Title>
            )}
            {props.section.content && props.section.content.body && (
              <P>{props.section.content.body}</P>
            )}
          </ColContent>
        </Row>
      </Wrapper>
    </StyledSection>
  );
}

CaseSection.propTypes = {
  section: PropTypes.object,
  title: PropTypes.string,
};

export default CaseSection;
