/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
// import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import Plx from 'react-plx';
import HeaderTitle from '../HeaderTitle';
import CaseCategories from '../CaseCategories';
import SocialLinks from '../SocialLinks';
import ScrollButton from '../Button/ScrollButton';
import Img from './Img';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(278deg, #fbab7e, #f7ce68);
  position: relative;
  padding: 0 20px;
  margin: 0 -20px;
  min-height: 650px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(19, 19, 19, 0), #131313),
      linear-gradient(to bottom, rgba(19, 19, 19, 0), #131313);
  }
  ${({ theme }) => theme.media.md.min`
    min-height: 900px;
    flex-direction: row;
    align-items: flex-start;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Names = styled.div`
  margin: 0 auto 0;
  text-align: center;
  position: relative;
  color: #fff;
  z-index: 3;
  ${({ theme }) => theme.media.md.min`
    margin: 0 auto 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const StyledImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActiveImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImgWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${ActiveImg} {
    opacity: 0;
  }
`;

const StyledSocialLinks = styled(SocialLinks)`
  display: none;
  flex-direction: row;
  margin: 0;
  padding: 20px;
  color: #fff;
  position: relative;
  > a {
    margin: 0 15px;
  }
  ${({ theme }) => theme.media.md.min`
    display: flex;
    position: fixed;
    margin: 0;
    right: 0;
    top: -10px;
    flex-direction: column;
    padding: 30px;
    > a {
      margin: 15px 0;
    }
   `}
`;

const Footer = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  > div {
    max-width: ${props => props.theme.maxWidth}px;
    margin: 0 auto;
  }
`;

const StyledScrollButton = styled(ScrollButton)`
  display: block;
  margin: 48px auto 0;
  text-transform: uppercase;
  ${({ theme }) => theme.media.md.min`
    display: block;
   `}
  ${({ theme }) => theme.media.lg.min`
margin: 160px auto 0;
`};
`;

const StyledPlx = styled(Plx)`
  margin: 0 auto;
  z-index: 2;
  text-align: center;
  a {
    display: inline-block;
  }
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: 500,
    properties: [
      {
        startValue: 0,
        endValue: 200,
        property: 'translateY',
        unit: 'px',
      },
    ],
  },
];

function Hero(props) {
  return (
    <Wrapper>
      <ImgWrapper className="backgroundImages">
        <StyledImg
          alt="case"
          src={require(`../../assets/images/${props.case.hero.img.active}`)}
        />
        <ActiveImg
          alt="case"
          src={require(`../../assets/images/${props.case.hero.img.inactive}`)}
        />
      </ImgWrapper>
      <StyledPlx parallaxData={parallaxData}>
        <Names>
          <HeaderTitle className="headerTitles" borderColor="#fff">
            {props.case.title}
          </HeaderTitle>
        </Names>
        <Link
          className="scrollDown"
          to="content"
          smooth
          duration={500}
          offset={-30}
        >
          <StyledScrollButton>Scroll Down</StyledScrollButton>
        </Link>
      </StyledPlx>
      <Footer>
        <div className="categories">
          <CaseCategories categories={props.case.categories} />
        </div>
      </Footer>
      <StyledSocialLinks color="light" />
    </Wrapper>
  );
}

Hero.propTypes = {
  case: PropTypes.object.isRequired,
};

export default Hero;
