import React from 'react';

function CheckIcon() {
  return (
    <svg
      height="9"
      viewBox="0 0 16 9"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#131313" fillRule="evenodd">
        <path
          d="m8.24264069 5.65685425 5.65685421-5.65685425 1.4142136 1.41421356-7.07106781 7.07106781-4.24264069-4.24264068 1.41421356-1.41421357z"
          opacity=".5"
        />
        <path d="m4.24264069 5.65685425 5.65685425-5.65685425 1.41421356 1.41421356-7.07106781 7.07106781-4.24264069-4.24264068 1.41421356-1.41421357z" />
      </g>
    </svg>
  );
}

CheckIcon.propTypes = {};

export default CheckIcon;
