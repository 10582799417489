import React, { useEffect, useState, useRef } from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Hamburger from './Hamburger';
import SocialLinks from '../SocialLinks';
import Strapline from './Strapline';
import H5 from '../H5';
import textStyles from '../Typography/Point';

import StijnLogo from '../Logos/StijnLogo';

const StyledHamburger = styled(Hamburger)`
  ${({ theme }) => theme.media.md.min`
    display:none;
   `}
`;

const StyledLogo = styled(StijnLogo)`
  margin: 20px 0 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
  > * {
    max-width: 100%;
    max-height: 100%;
  }

  ${props =>
    props.color === 'dark' &&
    css`
      color: #131313;
    `}
  ${props =>
    props.color === 'light' &&
    css`
      color: #fff;
    `}

  ${({ theme }) => theme.media.md.min`
    margin: 30px 0 0 30px;
    width: 80px;
    height: 80px;
   `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding: 20px;
  padding-top: 80px;
  background-color: #fff;
  position:relative;
  &:xbefore {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #fff;
  }

  }
  ${({ theme }) => theme.media.sm.min`
      margin-top: 0;
      padding: 30px;
   `}

  ${({ theme }) => theme.media.md.min`
    display:none;
   `}
   
`;

const StyledH5 = styled(H5)`
  font-size: 30x;
  xfilter: url(#myFilter);
  text-transform: uppercase;
  color: #131313;
  margin:0;
  /* You can change the color of the outline here by changing the color of the heading */

  ${props =>
    props.outline &&
    css`
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #131313;
      @supports ((text-stroke: 1px black) or (-webkit-text-stroke: 1px black)) {
        color: transparent;
        -webkit-text-stroke: 1px black;
        text-stroke: 2px black;
        text-shadow: none;
        filter: none;
      }
    `}
  ${({ theme }) => theme.media.md.min`
    font-size: 40px;
   `}
  ${({ theme }) => theme.media.lg.min`
    font-size: 40px;
   `}
`;

const StyledSocialLinks = styled(SocialLinks)`
  margin: -5px 0 -5px auto;
  flex-direction: column;
  margin: -5px 0 -5px auto;
  padding: 0 0 0 20px;
  color: #131313;
  > a {
    margin: 5px 0;
  }
  ${({ theme }) => theme.media.sm.min`
    margin: -5px 0 -5px auto;
    flex-direction: column;
    padding: 0 60px 0 30px;
    > a {
        margin: 5px 0;
      }
   `}
  ${({ theme }) => theme.media.md.min`
    display:none;
   `}
`;

const City = styled(StyledH5)`
  ${textStyles};
`;

const Menu = props => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <React.Fragment>
      <Wrapper ref={node} className={props.className}>
        <StyledLogo color={props.color} />
        <StyledHamburger
          color={props.color}
          {...(open ? { open } : null)}
          onClick={() => setOpen(!open)}
        >
          MENU
        </StyledHamburger>
        {open ? (
          <Content>
            <Strapline>
              <StyledH5 outline>
                Currently
                <br />
                working in
              </StyledH5>
              <City>Amsterdam</City>
            </Strapline>
            <StyledSocialLinks />
          </Content>
        ) : null}
      </Wrapper>
    </React.Fragment>
  );
};

Menu.defaultProps = {
  color: 'light',
};

Menu.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
};

export default Menu;
