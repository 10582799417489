import styled from 'styled-components';

const Section = styled.section`
  margin: 50px 0;
  ${({ theme }) => theme.media.md.min`
    margin: 75px 0;
   `}
  ${({ theme }) => theme.media.lg.min`
    margin: 150px 0;
   `}
`;

export default Section;
