/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Case from '../Case';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  ${({ theme }) => theme.media.md.min`
    flex-direction: row;
    width: 66.666%;
    margin: 0 %;
    > * {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
   `}
  ${({ theme }) => theme.media.lg.min`
    width: 75%;
    margin: 0 12.5%;
    > * {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
   `}
`;

const StyledCase = styled(Case)`
  height: 150px;
  ${({ theme }) => theme.media.md.min`
    height: 200px;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Cases = props => {
  const { cases, theme } = props;

  const w = window.innerWidth;
  let maxCases = 1;
  if (w >= theme.breakpoints.md) {
    maxCases = 2;
  }
  if (w >= theme.breakpoints.lg) {
    maxCases = 3;
  }

  return (
    <Wrapper>
      {cases.slice(0, 3).map((_case, i) => (
        <StyledCase
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          case={_case}
          delay={(i % maxCases) * 100}
          offsetBottom={-200}
        />
      ))}
    </Wrapper>
  );
};

Cases.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.object,
};

export default withTheme(Cases);
