import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import VisibilitySensor from 'react-visibility-sensor';
import Img from './Img';
import AnimatedButton from '../Button/AnimatedButton';

const StyledLogo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 55px;
  color: #fff;
  z-index: 3;
  > img {
    height: 100%;
  }
`;

const StyledImg = styled(Img)`
  transition: all 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HoveredImg = styled(Img)`
  transition: all 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImgWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
  ${StyledImg} {
    opacity: 1;
  }
  ${HoveredImg} {
    opacity: 0;
  }
  ${props =>
    props.hover &&
    css`
      ${StyledImg} {
        opacity: 0;
        transform: scale(1.05);
      }
      ${HoveredImg} {
        opacity: 1;
        transform: scale(1.05);
      }
    `}
`;

const Wrapper = styled(Link)`
  display: block;
  margin: 10px 0;
  position: relative;
  text-decoration: none!important;
  ${({ theme }) => theme.media.md.min`
    margin: 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
  }
`;

const StyledAnimatedButton = styled(AnimatedButton)`
  position: absolute;
  left: 80px;
  bottom: 20px;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Content = styled.div`
  position: relative;
`;

const Title = styled.h3`
  color: #fff;
  text-decoration: none !important;
  padding: 20px 0 0 20px;
  margin: 0;
  font-size: 21px;
`;

class Case extends React.Component {
  state = { hover: false };

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <VisibilitySensor
        partialVisibility="bottom"
        offset={{ bottom: this.props.offsetBottom }}
      >
        {({ isVisible }) => (
          <Spring
            native
            delay={300 + this.props.delay}
            to={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
            }}
          >
            {props => (
              <Wrapper
                to={`/case/${this.props.case.id}`}
                onMouseEnter={this.hoverOn}
                onMouseLeave={this.hoverOff}
              >
                <animated.div style={{ ...props }}>
                  <Content>
                    {this.props.case.logo && (
                      <StyledLogo>
                        <Img
                          src={require(`../../assets/logos/${this.props.case.logo}`)}
                          alt=""
                        ></Img>
                      </StyledLogo>
                    )}

                    <ImgWrapper
                      className={this.props.className}
                      {...(this.state.hover ? { hover: 'hover' } : {})}
                    >
                      <StyledImg
                        alt={this.props.case.title}
                        hover={this.state.hover}
                        src={require(`../../assets/images/${this.props.case.intro.img.inactive}`)}
                      />
                      <HoveredImg
                        alt={this.props.case.title}
                        hover={this.state.hover}
                        src={require(`../../assets/images/${this.props.case.intro.img.active}`)}
                      />
                    </ImgWrapper>
                    <StyledAnimatedButton
                      size="md"
                      {...(this.state.hover ? { hover: 'hover' } : {})}
                    >
                      view
                    </StyledAnimatedButton>
                  </Content>
                  {this.props.withTitle && (
                    <Title>{this.props.case.title}</Title>
                  )}
                </animated.div>
              </Wrapper>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    );
  }
}

Case.defaultProps = {
  delay: 0,
  offsetBottom: 0,
  withTitle: false,
};

Case.propTypes = {
  case: PropTypes.object.isRequired,
  delay: PropTypes.number,
  offsetBottom: PropTypes.number,
  className: PropTypes.string,
  withTitle: PropTypes.bool,
};

export default Case;
