import React from 'react';

function LinkedInLogo() {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m43 25.2619841v7.7380159h-4.2866476v-7.2197702c0-1.8136652-.6200173-3.0514207-2.1716417-3.0514207-1.1844684 0-1.8893463.8341565-2.199448 1.6410626-.1130855.2884657-.1421939.6899723-.1421939 1.0937659v7.5363624h-4.288089s.0577517-12.227921 0-13.4948248h4.2876705v1.9128374c-.0086953.0143065-.0199945.0297322-.0281784.0436007h.0281784v-.0436007c.569659-.9181951 1.5870101-2.2298675 3.8641579-2.2298675 2.8212324-.0000487 4.9361918 1.9287497 4.9361918 6.073839zm-17.5735439-12.2619841c-1.4669497 0-2.4264561 1.0069537-2.4264561 2.3311322 0 1.2953222.9317465 2.3328354 2.3695413 2.3328354h.0286434c1.4953605 0 2.4253401-1.0373186 2.4253401-2.3328354-.0281784-1.3241785-.9299796-2.3311322-2.3970687-2.3311322zm-2.1717812 20h4.2864616v-13.4948248h-4.2864616z"
        fill="currentColor"
        transform="translate(-23 -13)"
      />
    </svg>
  );
}

// We require the use of src and alt, only enforced by react in dev mode
LinkedInLogo.propTypes = {};

export default LinkedInLogo;
