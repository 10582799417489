import styled from 'styled-components';

const P = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
  ${({ theme }) => theme.media.md.min`
  font-size: 16px;
   `}
  ${({ theme }) => theme.media.lg.min`
  font-size: 16px;
   `}
`;

export default P;
