/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import styled from 'styled-components';
import H1 from '../../components/H1';

const Article = styled.main`
  padding: 50px 30px;
  text-align: center;
  color: #fff;
`;

export default function NotFound() {
  return (
    <Article>
      <H1>Page Not Found</H1>
    </Article>
  );
}
