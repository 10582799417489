import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Case from '../Case';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  > * {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 50px;
    padding: 0 20px;
    &:nth-child(2n + 1) {
      padding-left: 0;
    }
    &:nth-child(2n + 2) {
      padding-right: 0;
      margin-top: -25%;
    }
  }

  ${({ theme }) => theme.media.md.min`
  > * {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-right: 16.666%;
    margin-bottom: 40px;
    margin-top: 0!important;
    padding: 0!important;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(4n+2),
    &:nth-child(4n+3) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
   `}
  ${({ theme }) => theme.media.lg.min`
    > * {
      flex: 0 0 25%!important;
      max-width: 25%!important;
      margin-right: 12.5%!important;
      margin-bottom: 100px;
      &:nth-child(3n) {
        margin-right: 0!important;
      }
    }
   `}
`;

const Cases = props => {
  const { cases, theme } = props;

  const w = window.innerWidth;
  let maxCases = 1;
  if (w >= theme.breakpoints.md) {
    maxCases = 2;
  }
  if (w >= theme.breakpoints.lg) {
    maxCases = 3;
  }

  return (
    <Wrapper>
      {cases.map((_case, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Case
          key={_case.id}
          case={_case}
          delay={(i % maxCases) * 100}
          withTitle
        />
      ))}
    </Wrapper>
  );
};

Cases.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.object,
};

export default withTheme(Cases);
