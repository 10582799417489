import { createGlobalStyle } from 'styled-components';
import triangle from './assets/icons/stijn-bessem-icon-triangle-right.svg';

const timingsExit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  .map(
    item =>
      `&:nth-child(${item}):after {
        top: 0;
        transition: all 0.2s  ${(item - 1) * 0.125}s;
    };
  `,
  )
  .join('');

const timingsEnter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  .map(
    item =>
      `&:nth-child(${item}):after {
        top: 100%;
        transition: all 0.2s ${10 * 0.125 + 0.2 + (item - 1) * 0.125}s;
    };
  `,
  )
  .join('');

const timingsEnterMobile = [1, 2, 3, 4, 5, 6]
  .map(
    item =>
      `&:nth-child(${item}):after {
        top: 100%;
        transition: all 0.2s ${5 * 0.125 + 0.2 + (item - 1) * 0.125}s;
    };
  `,
  )
  .join('');

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    background-color: #131313;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  }

  #root{
  }

  .categories a {
    padding-right:25px;
    color: inherit;
    text-decoration: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right:10px;
      top:50%;
      margin-top:-5px;
      height: 10px;
      width: 5px;
      background-image: url(${triangle});
      background-size:cover;
    }
    &:hover {
      &:after {
        text-decoration: none;
        animation-duration: 1.5s
        animation-iteration-count: infinite;
        animation-name: triangleHover;  
  
      }
    }
  }

.grid > span:after {
  height: 100%;
  top: 100%;
}


.transition-group {
  position: relative;
}

.route-section {
  z-index:1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.louvre-exit {
  z-index:1;
  .xgrid > span:after {
    top:100%;
  }
}

.louvre-exit-active {
  transition: z-index .001s 0s;
  z-index:1;
  .grid > span {
    ${timingsExit};
  }
  .grid > span:after {
    top:0;
  }
}

.louvre-enter {
  z-index:1;
  .grid > span:after {
    top:0;
  }
}

.louvre-enter-active {
  transition: z-index .001s ${5 * 0.125 + 0.2}s;
  z-index:2;
  .grid > span {
    ${timingsEnterMobile};
  }
  .grid > span:after {
    top:100%;
  }
  @media (min-width: 768px) {
    transition: z-index .001s ${10 * 0.125 + 0.2}s;
    .grid > span {
      ${timingsEnter};
    }
  }
}

.backgroundImages {
  img:first-of-type {
    opacity: 1;
    transform: scale(1.1);
  }    
  img:last-of-type {
    opacity: 0;
    transform: scale(1.1);
  }
}

.headerTitles {
  opacity: 0;
}

.scrollDown button {
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}

.xlouvre-enter-active, .louvre-appear-active {
}

.louvre-enter-done {
  z-index: 3;
}

.louvre-exit,
.louvre-enter-done {
  .scrollDown button {
    animation: slideInDown 0.5s ease-in-out;
    animation-delay: 3s;
    animation-fill-mode: forwards;
  }
  .headerTitles {
    animation: fade 1.25s ease-in-out;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  .backgroundImages {
    img:first-of-type {
      animation: disappear 1.5s ease-in-out;
      animation-delay: 0.25s;
      animation-fill-mode: forwards;
    }
    img:last-of-type {
      animation: appear 1.5s ease-in-out;
      animation-delay: 0.25s;
      animation-fill-mode: forwards;
   }
  }
}


@keyframes slideInDown {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}


@keyframes triangleHover{
  0% {
    right: 10px;
    opacity: 1;
  }
  100% {
    right: 0;
    opacity: 0;
  }
}  

@keyframes zindex {
  from {top: 0px; background-color: yellow;}
  to {top: 200px; background-color: blue;}
}


@keyframes disappear {
  0% {
      opacity: 1;
      transform: scale(1.1);
  }
  100% {
      opacity: 0;
      transform: scale(1.0);
    }
}

@keyframes appear {
  0% {
      opacity: 0;
      transform: scale(1.1);
  }
  100% {
      opacity: 1;
      transform: scale(1.0);
    }
}

@keyframes fade {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
    }
}


@keyframes rotation {
		from {
				transform: rotateX(0deg);
		}
		to {
				transform: rotateX(359deg);
		}
}

@keyframes rotationDNA {
		from {
				transform: rotate(0deg);
		}
		to {
				transform: rotate(359deg);
		}
}

`;

export default GlobalStyle;
