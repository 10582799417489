import styled from 'styled-components';

const Cursor = styled.span`
  width: 0.25em;
  display: inline-block;
  margin-left: 0.15em;
  border-bottom: 3px solid #131313;
  animation: blink 0.7s steps(1) infinite;
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`;

export default Cursor;
