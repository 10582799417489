/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Header from '../../components/Header';
import CaseSections from '../../components/CaseSections';
import HeroCase from '../../components/HeroCase';
import { cases } from '../../assets/data/cases';

const Article = styled.main`
  position: relative;
  padding: 0 20px;
  ${({ theme }) => theme.media.lg.min`
   `}
`;

export default function CasePage(props) {
  const currentCase = cases.find(_case => _case.id === props.match.params.id);
  if (currentCase === undefined) {
    return <Redirect to="/" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{currentCase.title}</title>
      </Helmet>
      <Header color="light" />
      <Article>
        <HeroCase case={currentCase} />
        <div name="content" id="content">
          <CaseSections case={currentCase} />
        </div>
      </Article>
    </React.Fragment>
  );
}

CasePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
