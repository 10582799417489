import React from 'react';
import PropTypes from 'prop-types';
// import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Menu from '../Hamburger/Menu';

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding: 0 20px;
  left: 0;
  top: 0;
  right: 0;
  z-index: 7;
`;

const StyledMenu = styled(Menu)`
  ${({ theme }) => theme.media.md.min`
   `}
`;

function Header(props) {
  return (
    <React.Fragment>
      <Wrapper>
        <StyledMenu color={props.color} className={props.className} />
      </Wrapper>
    </React.Fragment>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Header;
