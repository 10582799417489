import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import P from '../P';

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 100%
  max-width: 100%;
  flex-direction: row;
  color: #fff;
  ${({ theme }) => theme.media.md.min`
    flex-direction: column;
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
   `}
  ${({ theme }) => theme.media.lg.min`
    flex: 0 0 25%
    max-width: 25%;
   `}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.25s ease-in-out;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position; bottom center;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(290deg, #fbab7e, #f7ce68);
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
  }
`;

const Box = styled.div`
  display: flex;
  height: 200px;
  padding: 5px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    ${Background} {
      transform: scale(1.1);
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }
  }

  > span {
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
    position: relative;
    z-index: 1;
    ${({ theme }) => theme.media.md.min`
    font-size: 28px;
    z-index:3;
   `}
    ${({ theme }) => theme.media.lg.min`
    font-size: 28px;
   `}
  }
  ${({ theme }) => theme.media.md.min`
    flex: 0 0 auto;
    max-width: none;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    > span {
      margin-top: auto;

   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const List = styled(P)`
  padding: 20px;
  margin: 0;
  line-height: 1.78;
  ${({ theme }) => theme.media.md.min`
    padding: 30px;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

function USP(props) {
  return (
    <Wrapper>
      <React.Fragment>
        <Box>
          <Background backgroundImage={props.backgroundImage} />
          <span>{props.title}</span>
        </Box>
        <List>
          {Children.toArray(
            <ReactMarkdown
              renderers={{ paragraph: React.Fragment }}
              source={props.children}
              escapeHtml={false}
            />,
          )}
        </List>
      </React.Fragment>
    </Wrapper>
  );
}

USP.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
};

export default USP;
