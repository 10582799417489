import styled from 'styled-components';
import NormalH3 from '../H3';
import textStyles from '../Typography/Point';

const H3 = styled(NormalH3)`
  margin: 0 0 30px 0;
  text-transform: uppercase;
  display: block;
  text-align: center;
`;

const StyledH3 = styled(H3)`
  ${textStyles};
`;

export default StyledH3;
