/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  ${props =>
    props.format === '16by9' &&
    css`
      padding-bottom: 56.25%;
    `}
  ${props =>
    props.format === '1by1' &&
    css`
      padding-bottom: 100%;
    `}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function Video(props) {
  return (
    <StyledVideo format={props.video.format} className={props.className}>
      <iframe
        title="video"
        src={props.video.url}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </StyledVideo>
  );
}

Video.propTypes = {
  video: PropTypes.object,
  className: PropTypes.string,
};

export default Video;
