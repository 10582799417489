import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SocialLink = styled.a`
  display: inline-flex;
  border: 1px solid currentColor;
  color: currentColor;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  position: relative;
  transition: all 0.25s ease-in-out;
  outline: 0;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 15px
    height: 1px;
    background-color: currentColor;
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    background-color: #131313;
    color: #fff;
    &:after {
      width:0;
    }
  }
  &:focus {
    outline: 0;
  }
  ${props =>
    props.color === 'dark' &&
    css`
      border: 1px solid #131313;
      color: #131313;
      &:after {
        background-color: #131313;
      }
      &:hover {
        background-color: #131313;
        color: #fff;
      }
    `}
  ${props =>
    props.color === 'light' &&
    css`
      border: 1px solid #fff;
      color: #fff;
      &:after {
        background-color: #fff;
      }
      &:hover {
        background-color: #fff;
        color: #131313;
      }
    `}
  ${({ theme }) => theme.media.md.min`
    height: 45px;
    width: 45px;
    &:after {
      width: 20px;
    }
  `};
`;

SocialLink.propTypes = {
  color: PropTypes.string,
};

export default SocialLink;
