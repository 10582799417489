import React from 'react';

function MailLogo() {
  return (
    <svg
      height="15"
      viewBox="0 0 20 15"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m42.375 15h-18.75l9.375 7.5087545zm-19.375 1.1345254v12.3810629c0 .1524525.0281373.2961179.0681046.4341974l6.8993791-7.1238828zm10 8.184093-2.0724837-1.7173862-7.2306536 7.3987678h18.4225164l-7.0968628-7.4112789zm10-8.1547262-6.9437582 5.6395364 6.8762419 7.1470104c.039379-.1387001.0674836-.2830188.0674836-.4348507z"
        fill="currentColor"
        transform="translate(-23 -15)"
      />
    </svg>
  );
}

// We require the use of src and alt, only enforced by react in dev mode
MailLogo.propTypes = {};

export default MailLogo;
