/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';
import AnimatedButton from '../Button/AnimatedButton';

const FooterContent = styled.div`
  padding: 0 20px 200px;
`;

const StyledAnimatedButton = styled(AnimatedButton)`
  display: block;
  margin: 0 auto 120px;
  text-transform: uppercase;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `};
`;

function Footer(props) {
  return (
    <FooterContent className={props.className}>
      <StyledAnimatedButton
        size="lg"
        vertical="vertical"
        onClick={() => scroll.scrollToTop()}
      >
        Back to top
      </StyledAnimatedButton>
    </FooterContent>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
