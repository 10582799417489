import styled from 'styled-components';
import NormalP from '../P';

const P = styled(NormalP)`
  position: absolute;
  z-index: 10;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  ${({ theme }) => theme.media.md.min`
    bottom: 40px;
   `}
  ${({ theme }) => theme.media.lg.min`
   `};
`;

export default P;
