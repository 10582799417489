/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../theme';

import { useWindowSize } from '../../hooks/useWindowSize';
import Container from './Container';
import GlobalStyle from '../../global-styles';
import ScrollToTop from './ScrollTop';

function SVG(props) {
  return (
    <div className={props.className}>
      <svg>
        <filter id="myFilter">
          <feMorphology
            operator="dilate"
            radius="1"
            in="SourceGraphic"
            result="THICKNESS"
          />
          <feComposite operator="out" in="THICKNESS" in2="SourceGraphic" />
        </filter>
      </svg>
    </div>
  );
}

SVG.propTypes = {
  className: PropTypes.string,
};

const StyledSVG = styled(SVG)`
  display: none;
`;

export default function App() {
  const [strokeCount, setStrokeCount] = useState(1);

  useWindowSize(
    ({ currSize }) => {
      const c = currSize.width >= 768 ? 10 : 5;
      if (c !== strokeCount) setStrokeCount(c);
    },
    [strokeCount],
  );

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s - Stijn Bessem" defaultTitle="Website" />
      <GlobalStyle />
      <StyledSVG />
      <Router>
        <ThemeProvider theme={theme}>
          <ScrollToTop strokeCount={strokeCount}>
            <Container strokeCount={strokeCount} />
          </ScrollToTop>
        </ThemeProvider>
      </Router>
    </React.Fragment>
  );
}
