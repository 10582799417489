import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring/renderprops';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const Wrapper = styled.div`
  overflow: hidden;
`;

function Title(props) {
  return (
    <Wrapper>
      <VisibilitySensor partialVisibility="bottom" offset={{ bottom: -75 }}>
        {({ isVisible }) => (
          <Spring
            native
            delay={300}
            to={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translate(0, 0)' : 'translate(0, 50px)',
            }}
          >
            {springProps => (
              <animated.div style={{ ...springProps }}>
                {Children.toArray(props.children)}
              </animated.div>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    </Wrapper>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
