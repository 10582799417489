import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable global-require */
import styled from 'styled-components';
import Plx from 'react-plx';

import Section from './Section';
import H2 from './H2';
import P from './P';
import Media from './Media';
import Title from '../Title';

const StyledSection = styled(Section)`
  &:after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: -20px;
    right: -20px;
    height: 50%;
    background-color: #1c1c1c;
    z-index: -1;
  }
  ${({ theme }) => theme.media.md.min`
    &:after {
      bottom: -75px;
    }
   `}
  ${({ theme }) => theme.media.lg.min`
    &:after {
      bottom: -150px;
    }
  `}
`;

const StyledMedia = styled(Media)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
  ${({ theme }) => theme.media.md.min`
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Row = styled.div`
  display: block;
  margin: 0;
  position: relative;
  color: #fff;
  text-align: center;
  ${({ theme }) => theme.media.md.min`
    margin: 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
  }
`;

const ColContent = styled.div``;
const ColMedia = styled.div`
  margin-top: 50px;
  overflow: hidden;
  height: ${props => (props.fixedHeight ? '600px' : 'auto')}
  display: flex;
  align-items: center;
  justify-content: center;
  > .Plx {
    flex: 1 1 auto;
    align-self: stretch;
  }
  ${({ theme }) => theme.media.md.min`
    margin-top: 100px;
    height: ${props => (props.fixedHeight ? '550px' : 'auto')}
   `}
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: '200vh',
    properties: [
      {
        startValue: 1.1,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
];

function CaseSection(props) {
  return (
    <StyledSection>
      <Wrapper>
        <Row>
          <ColContent>
            {props.section.content && props.section.content.title && (
              <Title>
                <H2>{props.section.content.title}</H2>
              </Title>
            )}
            {props.section.content && props.section.content.body && (
              <P>{props.section.content.body}</P>
            )}
          </ColContent>
          <ColMedia fixedHeight={!!props.section.img}>
            <Plx parallaxData={parallaxData}>
              <StyledMedia title={props.title} section={props.section} />
            </Plx>
          </ColMedia>
        </Row>
      </Wrapper>
    </StyledSection>
  );
}

CaseSection.propTypes = {
  section: PropTypes.object,
  title: PropTypes.string,
};

export default CaseSection;
