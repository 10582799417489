import React from 'react';
import styled from 'styled-components';

const GridLines = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  width: calc(100% - 40px);
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  top: 0;
  height: 100%;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 2px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.15) 1px, transparent 2px);
  background-size: 50% 100%, 50% 100%;
  background-position: top left, top center;

  ${({ theme }) => theme.media.md.min`
      background-size: 33.333% 100%,33.3333% 100%;
 `}
  ${({ theme }) => theme.media.lg.min`
      background-size: 25% 100%,25% 100%;
 `}
 &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 2px
    );
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
  > div {
    max-width: ${props => props.theme.maxWidth}px;
    width: calc(100% - 40px);
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 2px
      ),
      linear-gradient(90deg, rgba(255, 255, 255, 0.15) 1px, transparent 2px);
    background-size: 50% 100%, 50% 100%;
    background-position: top left, top center;
  }
  > span {
    display: inline-block;
    height: 100%;
    width: 20%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0;
      width: 100%;
      background-color: #131313;
    }
  }

  ${({ theme }) => theme.media.md.min`
    div {
      background-size: 25% 100%,25% 100%;
    }
    > span {
      width: 10%;
    }
 `}
  ${({ theme }) => theme.media.lg.min`
    div {
      background-size: 25% 100%,25% 100%;
    }
 `}
`;

function Grid() {
  return (
    <>
      <Wrapper className="grid">
        <GridLines></GridLines>
        {[...Array(10)].map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} />
        ))}
      </Wrapper>
    </>
  );
}

export default Grid;
