import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';
import Category from './Category';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  > * {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.media.md.min`
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    margin: 0;
    > * {
      flex: 0 0 50%;
      max-width: none;
    }
   `}
  ${({ theme }) => theme.media.lg.min`
    > * {
      flex: 0 0 25%;
      max-width: none;
    }
   `}
`;

function Categories(props) {
  return (
    <Wrapper>
      {props.categories.map(category => (
        <Category key={category.title} title={category.title}>
          <ReactMarkdown
            renderers={{ paragraph: React.Fragment }}
            source={category.body}
            escapeHtml={false}
          />
        </Category>
      ))}
    </Wrapper>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Categories;
