/* eslint-disable global-require */
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Plx from 'react-plx';
import HeaderTitle from '../HeaderTitle';
import Strapline from './Strapline';
import TypeWriter from '../TypeWriter';
import SocialLinks from '../SocialLinks';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(278deg, #fbab7e, #f7ce68);
  position: relative;
  padding: 0 20px;
  margin: 0 -20px;
  padding-bottom: 300px;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute
    bottom: 0;
    left: 0;
    right: 0;
    height: 350px;
    z-index: 3;
    background-image: linear-gradient(to bottom, rgba(10, 19, 19, 0), #131313);
  }
  ${({ theme }) => theme.media.md.min`
    padding-bottom: 400px;
    flex-direction: row;
    align-items: flex-start;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Names = styled.div`
  margin: 0 auto 0;
  text-align: center;
  position: relative;
  color: #131313;
  width: 100%;
  ${({ theme }) => theme.media.md.min`
    margin: 0 auto 0;
   `}
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const Mountain = styled.picture`
  height: 400px;
  position: absolute;
  bottom: -100px;
  width: 100%;
  left: 0;
  z-index:3;
  &:first-child {
    z-index:1;
  }
  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 100%;
    max-height 400px;
  }
  ${({ theme }) => theme.media.md.min`
    bottom: 0;
   `}

  ${({ theme }) => theme.media.xxl.min`
    width: 100%;
   `}
`;

const StyledTypeWriter = styled(TypeWriter)`
  font-weight: bold;
  display: block;
  ${({ theme }) => theme.media.md.min`
    display: inline-block;
   `}
`;

const StyledSocialLinks = styled(SocialLinks)`
  display: none;
  flex-direction: row;
  margin: 0;
  padding: 20px;
  color: #fff;
  position: relative;
  > a {
    margin: 0 15px;
  }
  ${({ theme }) => theme.media.md.min`
     display: flex;
    position: fixed;
    margin: 0;
    right: 0;
    top: -10px;
    flex-direction: column;
    padding: 30px;
    > a {
      margin: 15px 0;
    }
   `}
`;

const StyledPlx = styled(Plx)`
  margin: 0 auto;
  z-index: 2;
`;

const parallaxData = [
  {
    start: 'self',
    startOffset: 0,
    duration: 500,
    properties: [
      {
        startValue: 0,
        endValue: 200,
        property: 'translateY',
        unit: 'px',
      },
    ],
  },
];

function Hero(props) {
  return (
    <Wrapper id="hero">
      <Mountain>
        <source
          srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-back-2560x400.png')}`}
          media="(min-width: 1440px)"
        />
        <img
          srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-back-1440x400.png')}`}
          alt="…"
        />
      </Mountain>
      <Mountain>
        <source
          srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-front-2560x400.png')}`}
          media="(min-width: 1440px)"
        />
        <img
          srcSet={`${require('../../assets/mountains/stijn-bessem-portfolio-digital-mountain-front-1440x400.png')}`}
          alt="…"
        />
      </Mountain>
      <StyledPlx parallaxData={parallaxData}>
        <Names>
          <HeaderTitle borderColor="#000">{props.content.title}</HeaderTitle>
          <Strapline>
            <span>{props.content.typewriter.title}</span>{' '}
            <StyledTypeWriter dataText={props.content.typewriter.items} />
          </Strapline>
        </Names>
      </StyledPlx>
      <StyledSocialLinks color={props.color} />
    </Wrapper>
  );
}

Hero.propTypes = {
  content: PropTypes.object,
  color: PropTypes.string,
};

export default Hero;
