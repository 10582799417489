/* eslint-disable no-underscore-dangle */
/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import Header from '../../components/Header';
import LatestCases from '../../components/LatestCases';
import DNA from '../../components/DNA';
import Done from '../../components/Done';
import Comments from '../../components/Comments';
import MoreCases from '../../components/MoreCases';
import HeroHome from '../../components/HeroHome';
import Footer from '../../components/Footer';

import { cases } from '../../assets/data/cases';
import { site } from '../../assets/data/site';
// import Storyblok from '../../scripts/storyblok-client';

const Article = styled.main`
  position: relative;
  padding: 0 20px;
  ${({ theme }) => theme.media.lg.min`
   `}
`;

const StyledLatestCases = styled(LatestCases)`
  z-index: 3;
`;

const StyledFooter = styled(Footer)`
  z-index: 3;
  position: relative;
  overflow: hidden;
`;

export default function HomePage() {
  const [color, setColor] = useState('dark');

  useScrollPosition(
    ({ currPos }) => {
      const c = currPos.y <= -100 ? 'light' : 'dark';
      if (c !== color) setColor(c);
    },
    [color],
  );

  // const pos = useScrollPosition(0);
  // console.log('pos', pos);
  /*
  const [cases, setCasess] = useState([]);
  useEffect(() => {
    async function getCases() {
      const result = await fetch(
        `https://api.storyblok.com/v1/cdn/stories?token=${process.env.REACT_APP_API_TOKEN}`,
      );

      const { stories } = await result.json();
      const _cases = stories.map(story => ({
        country: story.country,
        launches: story.launches + 10,
      }));

      setCases(_cases);
    }
    getCases();
  }, []);
  */

  return (
    <React.Fragment>
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      <Header color={color} />
      <Article>
        <HeroHome color={color} content={site.hero} />
        <StyledLatestCases cases={cases} content={site.latest} />
        <DNA content={site.dna} />
        <Done content={site.done} />
        <Comments content={site.comments} />
        <MoreCases cases={cases} content={site.more} footer={site.footer} />
      </Article>
      <StyledFooter />
    </React.Fragment>
  );
}

HomePage.propTypes = {};
