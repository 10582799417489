/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const sizes = {
  md: {
    length: 45,
    circle: 16,
    fontSize: 12,
  },
  lg: {
    length: 75,
    circle: 18,
    fontSize: 16,
  },
};

const Circle = styled.span`
  content: '';
  sizing: {props => props.size}px;
  width: ${props => sizes[props.size].circle}px;
  height: ${props => sizes[props.size].circle}px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -${props => sizes[props.size].circle / 2}px;
  margin-right: -${props => sizes[props.size].circle / 2}px;
  display: inline-block;
  transition: all 0.25s ease-in-out;
  &:before {
    content: '';
    position: absolute;
    height: 1px;
    top: ${props => sizes[props.size].circle / 2}px;
    right: ${props => sizes[props.size].circle / 2}px;
    width: ${props => sizes[props.size].length}px;
    transition: all 0.25s ease-in-out;
    background: linear-gradient(90deg, #000 0%, #fff 0.1%, #fff 100%);
  }
`;

const Title = styled.div`
  position: absolute;
  font-size: ${props => sizes[props.size].fontSize}px;
  top: 50%;
  white-space: nowrap;
  height: ${props => sizes[props.size].circle}px;
  line-height: ${props => sizes[props.size].circle}px;
  margin-top: -${props => sizes[props.size].circle / 2}px;
  right: 100%;
  padding-right: 10px;
  margin-right: ${props => sizes[props.size].length / 2}px;
  transition: all 0.25s ease-in-out;
  text-transform: uppercase;
`;

const AnimatedButton = styled.button`
  width: ${props => sizes[props.size].length}px;
  height: ${props => sizes[props.size].length}px;
  border: 1px solid #fff;
  color: #fff;
  position: relative;
  display: inline-block;
  transition: all 0.25s ease-in-out;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  > span {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &:focus {
    outline: 0;
  }
  ${props =>
    props.vertical &&
    css`
      ${Circle} {
        &:before {
          height: ${sizes[props.size].length}px;
          width: 1px;
          background: linear-gradient(0deg, #000 0%, #fff 0.1%, #fff 100%);
        }
      }
      ${Title} {
        top: 100%;
        right: 50%;
        margin-right: 0;
        padding-right: 0;
        margin-top: ${sizes[props.size].length / 2}px;
        padding-top: 10px;
        transform: translateX(50%);
      }
    `}
  ${props =>
    props.down &&
    css`
      border-radius: 50%;
      ${Circle} {
        &:before {
          bottom: ${sizes[props.size].circle / 2}px;
          top: auto;
          height: ${sizes[props.size].length}px;
          width: 1px;
          background: linear-gradient(0deg, #000 0%, #fff 0.1%, #fff 100%);
        }
      }
      ${Title} {
        top: -100%;
        right: 50%;
        margin-right: 0;
        padding-right: 0;
        margin-top: ${sizes[props.size].length / 2}px;
        padding-top: -10px;
        transform: translate(50%, -${sizes[props.size].length / 2}px);
      }
    `}
  ${props =>
    props.hover &&
    css`
      background-color: #fff;
      ${Circle} {
        transform: translateX(${sizes[props.size].length}px);
        &:before {
          background: linear-gradient(90deg, #000 0%, #000 50%, #fff 50.5%);
        }
      }
      ${Title} {
        transform: translateX(${sizes[props.size].length / 2}px);
      }
    `} 
  ${props =>
    props.hover &&
    props.vertical &&
    css`
      background-color: #fff;
      ${Circle} {
        transform: translateY(-${sizes[props.size].length}px);
        &:before {
          background: linear-gradient(0deg, #000 0%, #000 50%, #fff 50.5%);
        }
      }
      ${Title} {
        transform: translate(50%, -${sizes[props.size].length / 2}px);
      }
    `}
  ${props =>
    props.hover &&
    props.down &&
    css`
      background-color: #fff;
      ${Circle} {
        transform: translateY(${sizes[props.size].length}px);
        &:before {
          background: linear-gradient(0deg, #fff 0%, #fff 50%, #000 50.5%);
        }
      }
      ${Title} {
        transform: translate(50%, 0);
      }
    `}
`;

const Button = props => {
  const [hover, setHover] = useState(false);

  const hoverOn = () => {
    setHover(true);
  };

  const hoverOff = () => {
    setHover(false);
  };

  /*
  componentWillReceiveProps(nextProps) {
    setState({ hover: nextProps.hover });
  } */

  return (
    <AnimatedButton
      type="button"
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      size={props.size}
      {...(props.down ? { down: 'down' } : {})}
      {...(props.vertical ? { vertical: 'vertical' } : {})}
      {...(hover ? { hover: 'hover' } : {})}
    >
      <Title size={props.size}>{Children.toArray(props.children)}</Title>
      <Circle size={props.size} />
    </AnimatedButton>
  );
};

Button.defaultProps = {
  size: 'sm',
};

Button.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.string,
  down: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  hover: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
