import { css } from 'styled-components';

const textStyles = css`
  display: inline-block;
  position: relative
  padding-bottom: 10px;
  &:after {
    content: '';
    left: 50%;
    bottom: 0;
    width: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: 1px;
    background-color: #fbab7e;
  }
`;

export default textStyles;
