/* eslint-disable react-hooks/ewidthhaustive-deps */
import { useRef, useLayoutEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

function getSize() {
  if (!isBrowser) return { width: 0, height: 0 };
  return { width: window.innerWidth, height: window.innerHeight };
}

export function useWindowSize(effect, deps, wait) {
  const size = useRef(getSize());

  let throttleTimeout = null;

  const callBack = () => {
    const currSize = getSize();
    effect({ prevSize: size.current, currSize });
    size.current = currSize;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }
    callBack();
    const handleResize = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('resize', handleResize);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, deps);
}

useWindowSize.defaultProps = {
  deps: [],
  wait: null,
};
