import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import H2 from './H2';
import USPS from './USPS';
import Section from '../Section';
import Title from '../Title';

const StyledSection = styled(Section)``;

const TitleWrapper = styled.div`
  margin: 0 0 20px 0;
  ${({ theme }) => theme.media.md.min`
    position: absolute;
    margin: 0 0 0 0;
   `}
  ${({ theme }) => theme.media.lg.min`
    margin: 0 0 -15% calc(12.5% + 30px);
   `}
`;

const Wrapper = styled.div`
  display: block;
  color: #fff;
  position: relative;
  max-width: ${props => props.theme.maxWidth}px;
  margin: 0 auto;
`;

function Done(props) {
  return (
    <StyledSection>
      <Wrapper>
        <TitleWrapper>
          <Title>
            <H2>
              <ReactMarkdown
                renderers={{ paragraph: React.Fragment }}
                source={props.content.title}
                escapeHtml={false}
              />
            </H2>
          </Title>
        </TitleWrapper>
        <USPS usps={props.content.usps} />
      </Wrapper>
    </StyledSection>
  );
}

Done.propTypes = {
  content: PropTypes.object,
};

export default Done;
