import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SocialLink from './SocialLink';
import LinkedInLogo from '../Logos/LinkedInLogo';
import MailLogo from '../Logos/MailLogo';
import PhoneLogo from '../Logos/PhoneLogo';

const Wrapper = styled.nav`
  display: flex;
  z-index: 7;
`;

function SocialLinks(props) {
  return (
    <Wrapper className={props.className}>
      <SocialLink
        color={props.color}
        href="https://www.linkedin.com/in/stijnbessem/"
        rel="noreferrer"
        target="_blank"
        aria-label="LinkedIn"
      >
        <LinkedInLogo />
      </SocialLink>
      <SocialLink
        color={props.color}
        href="tel:+31622653389"
        aria-label="Call me"
      >
        <PhoneLogo />
      </SocialLink>
      <SocialLink
        color={props.color}
        href="mailto:stijnbessem@msn.com"
        aria-label="Mail me"
      >
        <MailLogo />
      </SocialLink>
    </Wrapper>
  );
}

SocialLinks.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default SocialLinks;
